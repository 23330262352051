/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService} from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _url = environment.apiBaseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  helper = new JwtHelperService();

  constructor(private http: HttpClient, private router: Router) { }

  registerUser(user) {
    return this.http.post<any>(this._url + "/register", user)
  }

  loginUser(user) {
    return this.http.post<any>(this._url + "/login", user)
  }

  loggedIn = () => {
    let tk = localStorage.getItem('token');
    //console.log(this.isTokenExpired(tk));
    return !!tk;
  }

  logout = () => {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  getToken = () => {
    return localStorage.getItem('token');
  }

  isTokenExpired(token?: string): boolean {
   if(!token) token = this.getToken();
   if(!token) return true;

   const date = this.helper.getTokenExpirationDate(token);
   console.log('User connected until: '+date);
   if(date === undefined) return false;
   return !(date.valueOf() > new Date().valueOf());
 }

}
