/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PracticiensService } from '../../../services/practiciens/practiciens.service';
import * as L from 'leaflet';
import {MarkerClusterGroup} from "leaflet.markercluster";

@Component({
  selector: 'app-practiciens',
  templateUrl: './practiciens.component.html',
  styleUrls: ['./practiciens.component.css']
})
export class PracticiensComponent implements OnInit {

  map ;
  mainLayer ;
  markerCluster ;
  marker ;
  maxZoom  = 20;
  currentZoom = 7 ;

  searchType = 'Tous' ;
  searchTerm = '' ;

  origin = {
    lat : 48.8534,
    lng : 2.3488
    };

  icons =
  [new L.Icon({
    iconUrl:        'assets/img/find/patient_icon.png',
    iconSize:       [32, 32],
    iconAnchor:     [16, 16],
    popupAnchor:    [1, -20]}),
    L.icon({
      iconSize:     [25, 41],
      iconAnchor:   [10, 41],
      popupAnchor:  [2, -40],
      iconUrl:      "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl:    "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })]

  //practiciens = [] ;
  nbResult = 0 ;
  practiciens = []
  practiciensB = []
  skills = []
  constructor(
    private _practiciensServices: PracticiensService,
    private _router: Router
  ) {
    this._practiciensServices.getPracticiens()
    .subscribe(
      res => {
        this.practiciens = res;
        this.practiciensB = res;
        //console.log(res);
        this.nbResult = this.practiciensB.length;
        this._practiciensServices.getSkill()
        .subscribe(
          res => {
            this.skills = res ;
           },
          err => console.log(err)
        )
    },
      err => console.log(err)
    )
  }

  ngOnInit(): void {
    this.getUserLocation();
  }


  getUserLocation() {
    console.log(this.practiciens);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.origin.lat = position.coords.latitude;
        this.origin.lng = position.coords.longitude;
        });
    }
    this.createMap();
  }

  createMap() {
   this.mainLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: this.maxZoom,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
    });
    this.map = L.map('map', {
      center: [this.origin.lat, this.origin.lng],
      zoom: this.currentZoom,
      zoomControl: true, layers: [this.mainLayer]
    });
    //this.updateClusterMarker();
  }

  updateClusterMarker(){
    console.log(this.practiciens);
    this.markerCluster = new MarkerClusterGroup({singleMarkerMode: false});
    for(let practicien in this.practiciens){
      console.log(practicien);
      this.marker = L.marker(new L.LatLng(this.practiciens[practicien].lat, this.practiciens[practicien].lng), {icon: this.icons[1]});
      this.markerCluster.addLayer(this.marker);
    }
    this.map.addLayer(this.markerCluster);
  }
  showPracticien(lat,lng,name,add){

    if(this.marker) this.map.removeLayer(this.marker);
    this.marker = L.marker([this.origin.lat, this.origin.lng], { icon: this.icons[0] });
    this.marker.addTo(this.map).bindPopup('Ma position').openPopup();
    this.marker = L.marker([lat, lng], { icon: this.icons[1] });
    this.marker.addTo(this.map).bindPopup('<strong>'+name+'</strong><br>'+add);

/*      this.map.removeLayer(this.marker);
      this.markerCluster = new MarkerClusterGroup({singleMarkerMode: false});
      this.marker = L.marker(new L.LatLng(lat, lng), {icon: this.icons[1]});
      this.markerCluster.addLayer(this.marker);
      this.map.addLayer(this.markerCluster);
  */}

  rechercher(){

     let result = this.practiciens;
      if (this.practiciens && this.practiciens.length){
            result = this.practiciens.filter(item =>{
                if (this.searchTerm !='' && this.searchTerm && item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1) return false;
                if (this.searchType !='Tous'   && item.skill.toLowerCase().indexOf(this.searchType.toLowerCase()) === -1) return false;
                return true;
           })
      }

      this.practiciensB = result ;
      this.nbResult = this.practiciensB.length;
      if(this.marker) this.map.removeLayer(this.marker);
      if(this.markerCluster) this.map.removeLayer(this.markerCluster)

      //recentrer sur la position du patient
      this.map.setView(new L.LatLng(this.origin.lat, this.origin.lng, this.currentZoom));
      //ma position
      this.marker = L.marker([this.origin.lat, this.origin.lng], { icon: this.icons[0] });
      this.marker.addTo(this.map).bindPopup('Ma position');
      //cluster des practiciens
      this.markerCluster = new MarkerClusterGroup({singleMarkerMode: false});
      for(let practicien of this.practiciensB){
        this.marker = L.marker(new L.LatLng(practicien.lat, practicien.lng), {icon: this.icons[1]});
        this.marker.bindPopup('<strong>'+practicien.name+'</strong><br>'+practicien.address);
        this.markerCluster.addLayer(this.marker);
        }
      this.map.addLayer(this.markerCluster);


  }


}
