/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BaseComponent } from './base.component';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from '../../front/login/login.component';
import { RegisterComponent } from '../../front/register/register.component';
import { FormulairesComponent } from '../../front/formulaires/formulaires.component';
import { PagesComponent } from '../../front/pages/pages.component';
import { IlearnIndexComponent } from '../../front/i-learn/index/index.component';
import { PracticiensComponent } from '../../front/practiciens/practiciens.component';

@NgModule({
  declarations: [
    BaseComponent,
    LoginComponent,
    RegisterComponent,
    FormulairesComponent,
    PagesComponent,
    IlearnIndexComponent,
    PracticiensComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class BaseModule { }
