<form class="form-signin mt-4 mb-4">
    <h1>Vous connectez à votre espace</h1>

    <div class="form-group">
        <label for="inputEmail">Addresse mail</label>
        <input type="text" [(ngModel)]="loginUserData.email" id="inputEmail" name="email" class="form-control" placeholder="Entrez votre addresse mail" required autofocus>
    </div>

    <div class="form-group">
        <label for="inputPassword">Mot de passe</label>
        <input type="password" [(ngModel)]="loginUserData.password" id="inputPassword" name="password" class="form-control" placeholder="Entrez votre mot de passe" required>
    </div>

    <button (click)="loginUser()" class="btn btn-lg btn-primary btn-block" type="submit">Connectez-vous !</button>
</form>