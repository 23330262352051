/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  private _url = environment.apiBaseUrl;

	httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(private http: HttpClient, private router: Router) { }

	getPages() {
    	return this.http.get<any>(this._url + "/pages")
  	}

  	getPage(id) {
	    return this.http.get<any>(this._url + "/pages/"+id)
	  }

	postPage(id, page) {
	    if (id != null) {
	      return this.http.patch<any>(this._url + "/pages/"+id, page);
	    } else {
	      return this.http.post<any>(this._url + "/pages", page);
	    }
	}

	deletePage(id) {
		return this.http.delete<any>(this._url + "/pages/"+id)
	}

}
