<div id="box">
  <div id='title'>
    <h2 (click)="showArticles()">{{title | uppercase}}</h2>
  </div>
  <div *ngIf="show">
    <div id='largeur' *ngFor="let article of listArticle">
      <app-article-synopsis [title]="article.title" [image]="article.image" [synopsis]="article.synopsis" [id]="article.id" >
      </app-article-synopsis>
    </div>
  </div>
</div>
