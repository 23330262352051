import { Component, OnInit } from '@angular/core';
import {Section} from './section/section-interface';

import {DataSection} from './dataILearn';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.css']
})
export class LearnComponent implements OnInit {



  test : string = "Test titre";

  listSectionTest : Section[] = DataSection;

  constructor() { }

  ngOnInit(): void {
  }

}

