/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';

import { FormulairesService } from '../../../services/formulaires/formulaires.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class AdminBaseComponent implements OnInit {

  folders = [];

  constructor(
    private _formulairesService: FormulairesService
  ) { }

  ngOnInit(): void {
    this._formulairesService.getFolders()
    .subscribe(
      folders => {
        this.folders = folders.sort();
        // console.log(this.folders);
      },
      err => console.log(err)
    )
  }

}
