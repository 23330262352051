<div class="row">
<h2>iLearn</h2>
</div>
<br>
<div class="row">
    <div class="col-9">
        <h4>Articles <a routerLink="/admin/add-article" class="btn btn-info btn-sm">+</a></h4>
        <br>
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" (click)="sortType1 = 'name'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'name' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'name' && sortReverse1">↓</span> Nom</th>
                        <th scope="col" (click)="sortType1 = 'folder'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'folder' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'folder' && sortReverse1">↓</span> Dossier</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input1" [(ngModel)]='searchTermName1' placeholder="Name filter">
                        </td>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input2" [(ngModel)]='searchTermFolder1' placeholder="Folder filter">
                        </td>
                    </tr>

                    <tr *ngFor="let article of articles | filterListForm1 : searchTermName1 : searchTermFolder1 : sortReverse1 : sortType1 ">
                        <td (click)="editArticle(article)">{{article.name}}</td>
                        <td>{{article.folder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
