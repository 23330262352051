/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private _url = environment.apiBaseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private router: Router) { }

  getArticles() {
    return this.http.get<any>(this._url + "/articles")
  }

  getArticle(id) {
    return this.http.get<any>(this._url + "/articles/"+id)
  }

  postArticle(id, article) {
    if (id != null) {
      return this.http.patch<any>(this._url + "/articles/"+id, article);
    } else {
      return this.http.post<any>(this._url + "/articles", article);
    }
  }

  uploadPicture(id, article) {
    // console.log(formulaire);
    return this.http.post<any>(this._url + "/articles/"+id+"/upload-picture", article);
  }

  deleteArticle(id) {
    return this.http.delete<any>(this._url + "/articles/"+id)
  }

  folderArticle() {
    return this.http.get<any>(this._url + "/articles/folder")
  }

  featuredArticle() {
    return this.http.get<any>(this._url + "/articles/featured")
  }
}
