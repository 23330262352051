/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PracticiensService } from '../../../../services/practiciens/practiciens.service';

@Component({
  selector: 'app-list-practicien',
  templateUrl: './list-practicien.component.html',
  styleUrls: ['./list-practicien.component.css']
})
export class AdminListPracticienComponent implements OnInit {

  practiciens = [];
  practiciensB = [];
  skills = [] ;
  nbResult = 0 ;
  searchType = 'Tous' ;
  searchTerm = '' ;

  constructor(
    private _practiciensServices: PracticiensService,
    private _router: Router
  ) {
    this._practiciensServices.getPracticiens()
    .subscribe(
      res => {
        this.practiciens = res;
        this.practiciensB = res;
        // console.log(res);
        this.nbResult = this.practiciensB.length;
        this._practiciensServices.getSkill()
        .subscribe(
          res => {
            this.skills = res ;
           },
          err => console.log(err)
        )
      },
      err => console.log(err)
    )

  }

  ngOnInit(): void {
  }

  editPracticien(practicien){
    // console.log(practicien);
    this._router.navigate( ['/admin/edit-practicien/'+practicien._id]);
  }

  rechercher(){

     let result = this.practiciens;
      if (this.practiciens && this.practiciens.length){
            result = this.practiciens.filter(item =>{
                if (this.searchTerm !='' && this.searchTerm && item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1) return false;
                if (this.searchType !='Tous'   && item.skill.toLowerCase().indexOf(this.searchType.toLowerCase()) === -1) return false;
                return true;
           })
      }

      this.practiciensB = result ;
      this.nbResult = this.practiciensB.length;
  }

}
