/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  section = '' ;
  constructor() { }

  ngOnInit(): void {
  }

}
