/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Partie front
import { BaseComponent } from './components/layouts/base/base.component';
import { LoginComponent } from './components/front/login/login.component';
import { RegisterComponent } from './components/front/register/register.component';
import { FormulairesComponent } from './components/front/formulaires/formulaires.component';
import { MainComponent } from './components/front/main/main.component';
import { PagesComponent } from './components/front/pages/pages.component';
import { IlearnIndexComponent } from './components/front/i-learn/index/index.component';
import { IlearnShowComponent } from './components/front/i-learn/show/show.component';
//import { FindComponent } from './components/front/find/find.component';
import { PracticiensComponent } from './components/front/practiciens/practiciens.component';
import { LearnComponent } from './components/front/learn/learn.component'
import { TemplateComponent } from './components/front/template/template.component'

// Partie Admin
import { AdminBaseComponent } from './components/layouts/admin/base.component';
import { AdminDashboardComponent } from './components/admin/dashboard/dashboard.component';
import { AdminListFormComponent } from './components/admin/i-diagnose/list-form/list-form.component';
import { AdminAddFormComponent } from './components/admin/i-diagnose/add-form/add-form.component';
import { AdminEditFormComponent } from './components/admin/i-diagnose/edit-form/edit-form.component';
import { AdminAddPageComponent } from './components/admin/i-diagnose/add-page/add-page.component';
import { AdminEditPageComponent } from './components/admin/i-diagnose/edit-page/edit-page.component';
import { AdminListArticleComponent } from './components/admin/i-learn/list-article/list-article.component';
import { AdminAddArticleComponent } from './components/admin/i-learn/add-article/add-article.component';
import { AdminEditArticleComponent } from './components/admin/i-learn/edit-article/edit-article.component';
import { AdminListPracticienComponent } from './components/admin/i-find/list-practicien/list-practicien.component';
import { AdminAddPracticienComponent } from './components/admin/i-find/add-practicien/add-practicien.component';
import { AdminEditPracticienComponent } from './components/admin/i-find/edit-practicien/edit-practicien.component';


import { AuthGuard } from './guard/auth.guard';


const routes: Routes = [{
  path: '',
  component: BaseComponent,
  children: [{
    path: '',
    redirectTo: '/main',
    pathMatch: 'full'
  },{
    path: 'login',
    component: LoginComponent
  },{
    path: 'main',
    component: MainComponent
  },{
    path: 'practiciens',
    component: PracticiensComponent
  },{
    path: 'register',
    component: RegisterComponent
  },{
    path: 'formulaires',
    component: FormulairesComponent
  },{
    path: 'formulaires/:formulaireId',
    component: FormulairesComponent
  },{
    path: 'pages',
    component: PagesComponent
  },{
    path: 'pages/:pageId',
    component: PagesComponent
  }, {
    path: 'i-learn',
    component: IlearnIndexComponent
  }, {
    path:'i-learn/:articleId',
    component: IlearnShowComponent
  },{
    path: 'learn',
    component: LearnComponent
  },{
    path: 'learn/:articleId',
    component: TemplateComponent
  }]
},{
  path: 'admin',
  component: AdminBaseComponent,
  canActivate: [AuthGuard],
  runGuardsAndResolvers: "always",
  children: [{
    path: '',
    component: AdminDashboardComponent,
  },{
    path: 'add-form',
    component: AdminAddFormComponent
  },{
    path: 'idiagnose',
    component: AdminListFormComponent
  },{
    path: 'idiagnose/:folder',
    component: AdminListFormComponent
  },{
    path: 'edit-form/:id',
    component: AdminEditFormComponent
  },{
    path: 'add-page',
    component: AdminAddPageComponent
  },{
    path: 'edit-page/:id',
    component: AdminEditPageComponent
  },{
    path: 'ilearn',
    component: AdminListArticleComponent
  },{
    path: 'ifind',
    component: AdminListPracticienComponent
  },{
    path: 'add-article',
    component: AdminAddArticleComponent
  },{
    path: 'add-practicien',
    component: AdminAddPracticienComponent
  },{
    path: 'edit-article/:id',
    component: AdminEditArticleComponent
  },{
    path: 'edit-practicien/:id',
    component: AdminEditPracticienComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
