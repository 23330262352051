/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';
@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  constructor(private injector: Injector) { }
  authService = this.injector.get(AuthService);
  ngOnInit(): void {
  }

  logOut(){
    if(confirm("Êtes-vous sûr.e de vouloir vous déconnecter ?")) {
      this.authService.logout();
    }
  }

}
