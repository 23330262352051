/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEditForm2'
})
export class FilterPipeEditForm2 implements PipeTransform {

  transform(pages: any[], searchTermName2: string, searchTermFolder2: string, sortReverse2 : boolean, sortType2 : any): any[] {
    //console.log('call');
    //console.log(pages);
      let result = pages;
      if (pages && pages.length){
            result = pages.filter(item =>{
                if (searchTermName2 && item.value.name.toLowerCase().indexOf(searchTermName2.toLowerCase()) === -1) return false;
                if (searchTermFolder2 && item.value.folder.toLowerCase().indexOf(searchTermFolder2.toLowerCase()) === -1) return false;
                return true;
           })
        let colTri = [];
        let output = [];
        for(let i in result){
            if(sortType2=='name')colTri.push(result[i].value.name)
            if(sortType2=='folder')colTri.push(result[i].value.folder)
          }
        colTri = colTri.sort();
        if(!sortReverse2) colTri = colTri.reverse();
        let id = [];
        for(let element1 in colTri){
          for(let element2 in result){
            if(sortType2=='name') if(colTri[element1] == result[element2].value.name) if(id.indexOf(element2)==-1){id.push(element2); output.push(result[element2]);break}
            if(sortType2=='folder') if(colTri[element1] == result[element2].value.folder)  if(id.indexOf(element2)==-1) {id.push(element2); output.push(result[element2]);break}
          }
        }
        return output;
      }
    return result ;
    }
}
