/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

import { ArticlesService } from '../../../../services/articles/articles.service';

@Component({
  selector: 'app-ilearn-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IlearnIndexComponent implements OnInit {

  section = 'dilearnagnose';
  folders = [];
  articles = [];
  searchTerm = '';
  url = environment.apiBaseUrl;

  constructor(
    private _articlesServices: ArticlesService,
  ) { }

  ngOnInit(): void {
    this._articlesServices.folderArticle()
    .subscribe(
      folders => {
        this.folders = folders;

        this._articlesServices.featuredArticle()
        .subscribe(
          articles => {
            this.articles = articles;
          }
        )
      }
    )
  }

}
