/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { TemplateArticle } from './template.interface';

export const Sample: TemplateArticle = {
    id: 1,
    title: "Sample",
    image: "assets/img/learn/sample.jpg",
    synopsis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec felis ante. Integer id efficitur nisi. Duis dictum elit a diam scelerisque, quis imperdiet dui pellentesque. Praesent eu felis sit amet eros consequat faucibus quis in nisl. Duis a urna scelerisque, ultrices ante vel, dignissim enim.",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec felis ante. Integer id efficitur nisi. Duis dictum elit a diam scelerisque, quis imperdiet dui pellentesque. Praesent eu felis sit amet eros consequat faucibus quis in nisl. Duis a urna scelerisque, ultrices ante vel, dignissim enim. Sed lacinia a orci sit amet fringilla. Aliquam orci turpis, porttitor vitae purus eu, aliquam pretium lorem. Proin vel erat in nunc finibus placerat fringilla vel quam. Morbi nec est facilisis, viverra sapien nec, efficitur tortor. In aliquet orci ac diam consectetur, sagittis volutpat felis consectetur. Integer interdum dolor nisl.\n

    Aenean gravida odio tortor, nec ullamcorper velit commodo quis. Vivamus in pharetra mauris. Nullam elit libero, condimentum quis pretium eget, vehicula eget nibh. Quisque dictum dignissim elit consectetur tincidunt. Sed turpis quam, ultricies id est eu, tincidunt finibus sem. Fusce ac ipsum facilisis, dignissim purus id, vestibulum eros. Nulla pulvinar aliquam euismod. Cras ornare mauris quis nisi faucibus, nec ultrices quam volutpat. Fusce dictum ut ante volutpat sagittis.\n

    Sed arcu ligula, fermentum ut ornare a, ornare in felis. Nam diam mauris, placerat vel posuere non, vehicula et ipsum. Pellentesque scelerisque bibendum magna, et mattis diam dignissim non. Ut eu sapien nec orci fringilla porta quis ultricies lorem. Fusce tincidunt, arcu eget pellentesque dictum, enim velit tempor libero, sit amet bibendum justo nisl eu mi. Aenean sollicitudin lorem est, et condimentum risus egestas vel. Curabitur finibus, massa id rutrum vulputate, neque libero porta elit, lacinia facilisis sem lorem sit amet eros. Vestibulum nec justo ac augue laoreet scelerisque. Pellentesque facilisis est non metus cursus, non facilisis ipsum varius. Vestibulum commodo et arcu at aliquet. Praesent dictum lobortis est vel condimentum. Nam ut rutrum felis, rhoncus ultrices magna.\n`
}

export const LesHepatites: TemplateArticle = {
    id: 2,
    title: `LES HÉPATITES`,
    image: "assets/img/learn/foie.png",
    synopsis: "Les hépatites constituent un groupe extrêmement large de maladies du foie, aiguës ou chroniques. Elles se définissent par la destruction des cellules du foie (appelées 'hépatocytes') et la libération dans le sang des protéines (ou enzymes) contenues dans ces hépatocytes.",
    content: `Généralités et définition -\n

    Les hépatites constituent un groupe extrêmement large de maladies du foie, aiguës ou chroniques. Elles se définissent par la destruction des cellules du foie (appelées 'hépatocytes') et la libération dans le sang des protéines (ou enzymes) contenues dans ces hépatocytes. On parle alors de 'cytolyse hépatique', ce qui correspond à l’élévation du taux de ces enzymes, nommées ASAT (ou SGPO) et ALAT (ou SGPT), mesurée lors d’une prise de sang. La fonction du foie peut être transitoirement dégradée, et certains produits ne sont plus correctement éliminés dans la bile : la peau et le blanc des yeux se colorent en jaune, les urines sont foncées et les selles plus claires que d’habitude : c’est la « jaunisse », ou « ictère » dans le vocabulaire médical. Dans certains cas, lorsqu’un grand nombre de ces cellules du foie sont détruites, ou que l’infection survient sur un foie déjà fragilisé par la prise de médicaments ou une maladie chronique du foie, l’hépatite peut être grave, voire fulminante, et s’accompagner d’une défaillance de la fonction du foie, se traduisant notamment par une somnolence, puis un coma (on parle alors d’encéphalopathie hépatique) et de graves troubles de la coagulation du sang. En dehors de ces cas graves et heureusement exceptionnels, une hépatite ne donne généralement que peu de symptômes, et elle est découverte essentiellement a l’occasion d’une prise dans sang pour l’exploration d’une fatigue persistante.\n

    Principales causes d’hépatites\n

    Les principales causes d’hépatite sont : \n

    Les hépatites infectieuses. Parmi les microbes impliques, on retrouve essentiellement des virus. Il existe des virus spécifiquement agressifs pour le foie, comme les virus des hépatites A, B, C, D, E ( VHA, VHB, VHC, VHD, VHE), qui ne donnent le plus souvent pas ou peu de symptômes en dehors d’une fatigue lors de l’infection (une diarrhée ou des vomissements sont cependant typiquement observes lors d’infections par le VHA) ; et d’autres virus (comme par exemple l’Epstein-Barr Virus ou EBV, le Cytomegalovirus ou CMV) se manifestant le plus souvent pas des signes dits pseudo-grippaux, associant a des degrés divers le nez qui coule ou se bouche, une angine, une toux, des maux de tête, une fièvre, une fatigue, et des douleurs diffuses. Certaines bactéries, parasites ou champignons peuvent être à la source d’hépatites.\n
    Les hépatites liées a des produits toxiques pour le foie. Le plus connu est bien sur l’alcool en quantité excessive. L’absorption de certains produits toxiques, ou l’ingestion de certains champignons, peuvent également déclencher une hépatite toxique. Un grand nombre de médicaments peuvent être également toxiques pour le foie, ou bien aux doses usuellement utilisées, comme certains agents de chimiothérapie, antiépileptiques, ou médicaments contre le cholestérol, en particulier chez les patients souffrant d’une maladie chronique du foie ; ou bien lorsque les doses prescrites sont dépassées, comme par exemple le paracétamol.\n
    La surcharge en graisse du foie. On parle de stéatose hépatique non alcoolique. Il s’agit d’une maladie chronique survenant chez les personnes en surpoids ou obèses, le plus souvent diabétiques. D’autres éléments de l’organisme, comme le fer ou le cuivre peuvent également venir s’accumuler dans le foie et conduire a la destruction de ses cellules, le plus souvent dans le cadre de maladies génétiques que sont l’hémochromatose primitive (surcharge en fer) et la maladie de Wilson (surcharge en cuivre).\n
    Les maladies auto-immunes ou apparentées. Il s’agit par exemple des hépatites auto- immune dans lequel le système immunitaire du patient s’attaque aux cellules du foie ; d’autres maladies, comme la cirrhose biliaire primitive, caractérisées par la réduction du calibre des petits canaux biliaires dans le foie peuvent expliquer des anomalies dans les tests du foie.\n
    Des maladies vasculaires, comme l’obstruction par un caillot de sang d’un des vaisseaux du foie, peuvent expliquer une hépatite. Dans le cas d’obstruction de la veine porte qui nourrit le foie, on parle de thrombose porte, et dans le cadre d’obstruction des veines sus-hépatique, qui drainent le foie, on parle de syndrome de Budd Chiari.\n

    Hépatite aiguë ou chronique ?\n

    L’immense majorité des hépatites est aiguë, c'est-à-dire ne persiste pas dans le temps au-delà de 3 a 6 mois. Certaines hépatites virales cependant, liées aux virus VHB, VHC, et VHD, peuvent persister indéfiniment car l’organisme n’est pas capable d’éliminer le virus seul. On parle alors d’hépatite virale chronique. Un traitement médicamenteux contre le virus peut être nécessaire, permettant de guérir ou de contrôler l’infection dans la majorité des cas. Les hépatites auto-immunes sont également des hépatites chroniques. Elles évoluent par poussées, et nécessitent des traitements au long cours.\n

    Diagnostic\n

    Les hépatites aiguës sont parfois découvertes par hasard lors d’une prise de sang réalisée pour un autre motif. Elles peuvent être découvertes dans l’exploration de symptômes comme une fatigue persistante, une diarrhée et des vomissements, une jaunisse, ou des complications graves comme le coma.\n
    De même, il est fréquent que les hépatites chroniques, en particulier virales, ne donnent pas de symptômes pendant plusieurs années : elles sont alors diagnostiquée fortuitement à l’occasion d’un dépistage avant une intervention chirurgicale ou un don de sang, ou bien tardivement lorsque l’on cherche la cause d’une destruction partielle du foie appelée cirrhose.\n
    Le diagnostic d’une hépatite et de sa cause repose sur des tests biologiques fait sur des prises de sang, exception faite des causes vasculaires qui nécessitent un examen radiologique du foie comme un scanner ou une échographie. Dans des cas exceptionnels ou le diagnostic est délicat, une biopsie du foie peut être nécessaire.\n

    Principes du traitement\n

    La plupart des hépatites guérit spontanément sans traitement : l’organisme élimine le virus ou le microbe en cause, le traitement responsable est arrête. Quand un caillot de sang s’est forme dans les veines du foie, un traitement visant à fluidifier le sang, dit traitement anticoagulant, est prescrit, afin de dissoudre le caillot. Dans les hépatites virales chroniques, en fonction de plusieurs paramètres, le médecin peut être amené à prescrire un traitement, permettant de guérir complètement du virus pour l’hépatite C, ou de contrôler l’infection pour l’hépatite B. Les maladies auto-immunes nécessitent un traitement temporaire ou prolonge, pouvant associer la cortisone et ses dérives a des produits dits immun suppresseurs qui réduisent l’agressivité des défenses immunitaires et donc freinent la maladie.Dans tous les cas, une surveillance de l’état du patient, mais aussi de la normalisation des tests du foie a la prise de sang, est indispensable.\n

    Mots clefs : cytolyse ; hépatite aiguë ; hépatite chronique ; hépatite fulminante ; cirrhose\n
    Mots clefs anglais: acute hepatitis; Hepatitis B virus ; Hepatitis C virus ; liver failure ; liver cirrhosis\n

    Pour ceux qui souhaitent en savoir plus :\n
    - Sur les maladies du foie en général   www.hepatoweb.orghttp://www.afef.asso.fr/\n
    - Sur les maladies rares du foie    www.orpha.net\n`
}

export const IntoleranceGluten: TemplateArticle = {
    id: 3,
    title: "INTOLÉRENCE AU GLUTEN ou MALADIE COELIAQUE",
    image: "assets/img/learn/colon.jpg",
    synopsis: "Le gluten est une protéine contenue dans certaines céréales, à savoir le blé, l’orge et le seigle. La maladie cœliaque se caractérise par une réaction anormale du système immunitaire à la présence de gluten dans l’intestin grêle.",
    content: `Généralités et définition\n

     En conséquence, la première couche de l’intestin grêle, appelée la muqueuse, qui comporte normalement des replis appelés villosités servant à absorber les aliments, est détruite par les défenses immunitaires. Dans ces conditions, les aliments ne sont pas correctement absorbés, et apparaissent une diarrhée, mais aussi une carence en énergie aboutissant a une perte de poids, mais aussi dans certaines vitamines et minéraux essentiels (vitamine D, calcium, fer, ….).Il faut savoir que l’intolérance au gluten ne survient que chez des individus prédisposés génétiquement. Cette prédisposition génétique, caractérisée par la présence de l’haplotype HLA DQ2 ou DQ8 sur les globules blancs du patient, peut être recherchée dans les cas de diagnostics difficiles. Cependant, il ne s’agit pas d’une maladie héréditaire que l’on risque de transmettre à ses enfants. Dans la majorité des cas, le diagnostic est suspecté devant des signes tels qu’une diarrhée importante et prolongée dans le temps, évoluant depuis plusieurs mois, voire années, des douleurs au ventre, et un amaigrissement. Dans certains cas, des maladies classiquement associées, comme l’ostéoporose (ou fragilité osseuse) précoce, la dermatite herpétiforme (maladie de peau qui se présente comme une éruption de petits boutons roses sur les membres, des maladies auto-immunes de la thyroïde, et des troubles de la fertilité chez les femmes.\n

    Diagnostic\n

    Le diagnostic est confirmé par deux examens :\n

    1. Une prise de sang permettant de rechercher la présence d’anticorps spécifiques de la maladie, appelés anticorps anti-transglutaminase. La prise de sang permet également de rechercher des carences éventuelles.\n

    2. Une Endoscopie oeso-gastro-duodénale, parfois encore appelée fibroscopie ou gastroscopie, qui consiste a examiner, le plus souvent sous anesthésie générale, l’œsophage, l’estomac et la première partie de l’intestin grêle appelée duodénum à l’aide d’une camera montée sur un tuyau souple que l’on passe par la bouche. Cet examen permet en l’occurrence de réaliser des biopsies du duodénum qui, après analyse au microscope (ou analyse anatomo-pathologique) pourront mettre en évidence la destruction des villosités intestinales, généralement décrites comme l’ « atrophie villositaire ».\n

    Ces deux examens suffisent à retenir le diagnostic. Dans quelques rares cas cependant, une recherche du marqueur de prédisposition génétique peut être nécessaire, et sera réalisée dans des centres experts hospitaliers.\n

    Une fois le diagnostic confirmé, le traitement consiste en l’exclusion totale et définitive du gluten de l’alimentation. Compte tenu de la présence de gluten « caché » dans un très grand nombre d’aliments, et d’autre part de la possibilité de redévelopper des symptômes même en consommant de très petites quantités de gluten, une consultation avec un(e) diététicien(ne) est indispensable. Sous régime sans gluten bien conduit, les anticorps disparaissent, les villosités intestinales repoussent, et les symptômes disparaissent, permettant aux patients de mener une vie tout à fait normale. En l’absence de régime sans gluten bien suivi, les symptômes et les carences alimentaires persistent voire s’aggravent, et il existe un risque majoré de développer un cancer grave de l’intestin grêle appelé lymphome T intestinal. Quoi qu’il en soit, une surveillance médicale prolongée, rapprochée dans les premiers mois, puis espacée le reste de la vie, reste nécessaire.\n

    Qu’en est-il de l’hypersensibilité au gluten ?\n

    Un syndrome appelé hypersensibilité au gluten a été rapporté récemment. Des patients se plaignant de troubles digestifs mineurs, tels qu’une diarrhée intermittente, des ballonnements, ou un inconfort digestif, verraient leur état amélioré par l’exclusion du gluten de leur alimentation. Ce syndrome est en cours de description est il n’existe pour l’instant aucun test permettant de le diagnostiquer avec certitude comme pour la maladie coeliaque ou « vraie » intolérance au gluten. En particulier, ces patients ne présentent ni les marqueurs génétiques, ni les anticorps, ni les anomalies des biopsies duodénales observées chez les patients atteints de la maladie cœliaque. Il n’existe à ce jour aucune base scientifique ou médicale pour recommander aux personnes se plaignant de perturbations du transit intestinal ou de douleurs au ventre de retirer le gluten de leur alimentation. Cependant, si le régime sans gluten est très contraignant et coûteux, il n’est absolument pas dangereux pour la santé, et connait un grand essor depuis une dizaine d’années.\n

    Mots clefs : maladie coeliaque ; intolérance au gluten ; hypersensibilité au gluten\n
    Mots clefs anglais: celiac disease ; non celiac gluten hypersensitivity\n
    Pour ceux qui souhaitent en savoir plus : Site de la société française des intolérants au gluten : http://www.afdiag.fr/\n`
}

export const TumeurPancreas: TemplateArticle = {
    id: 4,
    title: "TUMEURS DU PANCRÉAS",
    image: "assets/img/learn/pancreas.jpg",
    synopsis: "Le terme de “tumeur du pancréas” est un terme générique qui regroupe les tumeurs bénignes et les tumeurs malignes (ou cancers) du pancréas. Le pancréas est une petite glande située sous le foie, abouchée à l'intestin grêle, responsable en particulier de la régulation de l'assimilation des sucres par sa sécrétion d'insuline et de glucagon (voir la page diabète).",
    content: `Une tumeur du pancréas peut être découverte à l’occasion :\n

    - de douleurs au ventre ou au dos, à peu près à la hauteur de l’estomac, associées à une perte de poids avec fatigue et perte d’appétit, \n

    - d’une jaunisse (ou ictère) en rapport avec la compression des canaux biliaires provenant du foie et passant au contact du pancréas, \n

    - d’une crise douloureuse d’inflammation du pancréas appelée pancréatite, \n

    - d’une masse ou d’une boule palpée sur le ventre, \n

    - ou enfin de symptômes exceptionnels (hypoglycémies, douleurs ou saignement digestifs en rapport avec des ulcères de l’estomac, malaises avec palpitations, diarrhée) en rapport avec certaines tumeurs rares, dites neuro-endocrines, produisant des hormones en quantité excessive. \n

    Dans un grand nombre de cas, la tumeur est découverte par hasard sur un examen radiologique (scanner ou IRM) réalisé pour un autre motif. \n

    Une fois la lésion découverte, deux, voire trois examens d’imagerie, pourront être réalisés pour préciser la nature de la tumeur et ne pas méconnaître un cancer du pancréas qui devra être traité dans les semaines à venir. En plus du scanner, une IRM, ou plus souvent une écho-endoscopie pancréatique seront organisés. L’écho-endoscopie pancréatique est un examen du pancréas réalisé sous anesthésie générale à l’aide d’une sonde miniaturisée au bout d’un tube souple qui est place dans l’estomac et le duodénum en passant par la bouche, afin d’examiner le pancréas de très près. Cet examen permet également le prélèvement de biopsies.\n

    Tumeurs bénignes\n

    L’immense majorité des tumeurs du pancréas est bénigne, c’est à dire ne met pas directement en danger la vie du patient. Cependant, parmi les tumeurs bénignes, certaines peuvent, dans le temps, évoluer vers la malignité, c’est à dire se révéler capables d’envahir les organes avoisinants. Ces tumeurs, doivent être surveillées de près pour être opérées à temps. Les tumeurs bénignes sont principalement de tumeurs arrondies et contenant du liquide, dites tumeurs kystiques. Parmi celles-ci, on compte les TIPMP (tumeurs intra-canalaires papillaire et mucineuses du pancréas) et les cystadénomes. Ces tumeurs seront tout d’abord caractérisées aussi précisément que possible, souvent à l’aide de deux ou trois examens d’imagerie, voire d’une biopsie, afin de ne pas méconnaitre un cancer du pancréas; puis, en fonction de leur taille, de leur croissance dans le temps, et de leur situation dans le pancréas, elle seront surveillées par des examens radiologiques une ou deux fois par an, ou retirées au cours d’une intervention chirurgicale.\n

    Tumeurs malignes ou cancers\n

    Les tumeurs malignes ou cancers du pancréas regroupent essentiellement les adénocarcinomes et les tumeurs neuro-endocrines. Le traitement standard, qui consiste à retirer la tumeur ainsi que la moitié du pancréas dans laquelle elle s’est développée et les ganglions avoisinants n’est pas toujours possible. Ainsi, une chimiothérapie anticancéreuse éventuellement associée à une radiothérapie peut être proposée dans un premier temps, et la chirurgie ré-envisagée seulement si la tumeur a réduit de volume. Les décisions de traitement sont prises pour chaque patient au cas par cas au cours de réunions médicales et chirurgicales regroupant des médecins experts appelées réunions de concertations pluridisciplinaires afin de proposer au patient le meilleur traitement possible.\n

    Mots clefs : tumeur pancréatique ; cancer du pancréas ; tumeur neuroendocrine ; adénocarcinome pancréatique ; TIPMP\n

    Mots clefs anglais : pancreatic cancer ; neuroendocrin tumor ; IPMN\n

    Pour ceux qui souhaitent en savoir plus : Le site du thesaurus national de cancérologie digestive : http://www.tncd.org/ Le site de l’institut national du cancer : http://www.e-cancer.fr/cancerinfo/les-cancers/cancer-du-pancreas/les-points-cles\n`
}



export const AlgieVasculaire: TemplateArticle = {
    id: 5,
    title: "Traiter une algie vasculaire de la face",
    image: "assets/img/learn/foret.jpg",
    synopsis: `Le traitement comprend le traitement de la crise (dit "symptomatique", qui vise à soulager la douleur) et le "traitement de fond" (qui vise à diminuer la fréquence des crises)`,
    content: `En traitement de crise, seuls deux traitements ont démontré leur efficacité :\n

    - Le sumatriptan injectable calme la crise en 5 à 10 minutes : il s'administre en injection sous-cutanée de 6 mg (Imiject®), est prescrit sur une ordonnance protégée (de médicament d'exception), et agit comme un agoniste des récepteurs à la sérotonine qui entraîne une réduction du calibre des vaisseaux sanguins ("vasoconstriction") ; compte-tenu de cet effet, le sumatriptan est formellement contre-indiqué en cas d'antécédent de maladie coronarienne, d'AVC ischémique, d'artérite des membres inférieurs, de syndrome de Wolff Parkinson White, d'hypertension artérielle non contrôlée et de syndrome de Raynaud. La dose maximale recommandée d'Imiject® est de 2 injections par jour.\n
    - En cas d'échec ou de contre-indication au sumatriptan, ou encore de crises très fréquentes ou rapprochées , l'oxygénothérapie au masque (7 litres/min pendant 15 min) est un traitement très efficace.\n

    Le traitement de fond n'est pas systématique ; il vise à diminuer la fréquence des crises ou leur survenue en cas d'atteinte chronique.\n

    - Le vérapamil (Isoptine®), bien que hors AMM, est actuellement considéré comme le plus efficace, à la dose de 120 mg 4 à 6 fois par jour, après vérification de l'absence de trouble de la conduction cardiaque sur un ECG.\n
    - Le carbonate de lithium (Téralithe®), hors AMM également, est utilisé dans les formes chroniques à la dose moyenne de 750 mg/jour, avec vérification sanguine de la lithémie (inférieure à 0,9 mEq/l).\n
    - L'indométacine (Indocid®) a parfois un effet remarquable à 200 mg/jour par voie rectale.\n

    Dans tous les cas, une consultation en centre spécialisé dans le traitement des céphalées (Hôpital Lariboisère à Paris notamment) est vivement recommandée.\n`
}

export const RincageOculaire: TemplateArticle = {
    id: 6,
    title: "Rinçage oculaire",
    image: "assets/img/learn/eye_solution.jpg",
    synopsis: "En cas de projection dans les yeux de produits liquides ou gazeux, de corps étranger(s) ou simplement de conjonctivite avec sécrétions plus ou moins sales, vous devez être capable de bien vous rincer les yeux. En voici la technique. Dans tous les cas, évitez de vous frotter les yeux.",
    content: `En cas de conjonctivite, vous devez rincer plusieurs fois par jour avec des dosettes de sérum physiologique (Dacudoses®, Dacryosérum®, Acide borique, Borax®), disponible dans toutes les pharmacies et sans ordonnance.\n

    Commencez par bien vous laver les mains avant de verser le sérum physiologique.\n
    Retirez vos lentilles de contact si vous en portez. Ces lentilles ne devront pas être remises pendant tout le temps que durera la conjonctivite.\n
    Penchez la tête légèrement en arrière, soulevez la paupière inférieure avec l'index, et de l'autre main videz le contenu de la dosette de sérum par jets répétés et abondants dans l'œil maintenu ouvert. Vous devez chasser toutes les sécrétions sales pour les empêcher de stagner dans l'œil et sous les paupières. Il est normal que le sérum, abondamment versé, s'écoule tout aussi abondamment le long de votre joue. Une compresse peut être utilisée pour éponger le sérum et les sécrétions ainsi déversés. En cas de difficulté à maintenir l'œil ouvert, ou pour les jeunes enfants, vous pouvez vous faire aider d'une autre personne.\n
    Les lavages doivent être abondants et fréquents (4 à 6 fois par jour en cas de conjonctivite virale ou bactérienne). Malgré ces lavages bien réalisés, une conjonctivite peut prendre plus d'une semaine à guérir.\n
    Lavez-vous bien les mains une fois les lavages terminés (en cas de conjonctivite infectieuse), pour éviter le risque de contagion dans l'entourage.\n

    En cas d'urgence (brûlure, projection de liquide toxique), vous devez commencer immédiatement à rincer ! ; l'urgence est de commencer le lavage le plus tôt possible ! Si vous n'avez pas de sérum physiologique sous la main, il est préférable de rincer à l'eau minérale naturelle, ou même à l'eau du robinet, plutôt que ne rien faire du tout. Ce rinçage doit être abondant ("à grande eau"), continu, et de longue durée :\n

    - 5 à 10 minutes pour les produits moyennement irritants\n
    - 20 minutes minimum pour les produits très irritants ou les acides (batteries de voiture)\n
    - 60 minutes minimum pour les produits basiques (Javel, soude, Destop®, ammoniac, ciment, chaux) qui sont les plus corrosifs et pénétrants, donc les plus dangereux.\n
    Dans ces cas de brûlure grave, une consultation ophtalmologique aux urgences s'impose une fois le rinçage effectué.\n`
}

export const Chalazion: TemplateArticle = {
    id: 7,
    title: "Traiter un chalazion",
    image: "assets/img/learn/chalazion.jpg",
    synopsis: "Le traitement repose sur deux piliers : les massages quotidiens des paupières pour vidanger les glandes sécrétant le meibum (corps gras) et l'application d'une pommade corticoïde sous la paupière lorsque le chalazion est inflammatoire. Malgré les traitements, les chalazions sont hélas souvent récidivants.",
    content: `A. Massage des paupières :\n
    Un chalazion est dû à une mauvaise sortie du meibum des paupières ; les glandes de sortie du meibum étant bouchées, le meibum s'accumule en amont dans la paupière pour former une sorte de point noir géant, appelé chalazion. Il est donc essentiel de vidanger régulièrement les sécrétions grasses contenues dans les paupières, pour stimuler l'ouverture canaux de sortie et éviter que le meibum ne s'accumule.\n

    Réchauffez vos paupières tous les matins en appliquant une compresse ou un gant de toilette très chaud pendant 5 minutes sur les paupières, de manière à ramollir les sécrétions\n
    Masser le bord libre (près des cils) les paupières ainsi réchauffées, par pressions répétées à 2 doigts, devant un miroir, comme pour exprimer le contenu d'un point noir, comme montré dans la video. Des sécrétions sortiront progressivement le long du bord libre des cils\n
    Ce massage est à répéter le plus souvent possible, idéalement tous les jours dans les premiers temps du traitement, puis à espacer progressivement.\n

    B. Pommade corticoïde :\n

    Dans les chalazions inflammatoires, une pommade anti-inflammatoire à base de corticoïdes devra vous être prescrite par un médecin. Les pommades les plus fréquemment utilisées se nomment Sterdex®, Maxidrol®, Frakidex®. Elle doit s'appliquer matin et soir entre l'œil et la paupière pendant une dizaine de jours, en parallèle des massages, de moins en moins difficiles à effectuer plus le chalazion réduit de taille.\n
    Pour appliquer la pommade, soulevez légèrement votre paupière inférieure et déposez la pommade sous la paupière (dans l'œil), comme montré dans cette video.\n`
}

export const Cataracte: TemplateArticle = {
    id: 8,
    title: "CATARACTE",
    image: "assets/img/learn/cataracte.png",
    synopsis: "Le cristallin est une lentille intra-oculaire transparente située derrière l’iris, qui peut être assimilée à l’objectif d’un appareil photographique. La cataracte désigne l’opacification partielle ou totale du cristallin, le plus souvent liée à une altération de la vision.",
    content: `La cataracte se caractérise par la modification de transparence, d’indice et de géométrie du cristallin, responsable en évoluant d’une gêne visuelle plus ou moins marquée :\n

    - baisse de l’acuité visuelle et de la sensibilité aux couleurs et aux contrastes,\n
    - myopisation d’indice souvent (l’oeil voit plus flou de loin)\n
    - aberrations optiques de haut degré pouvant entraîner éblouissements (photophobie), halos lumineux et vision double (diplopie monoculaire).\n

    Les causes de cataracte sont largement dominées par la cataracte sénile, liée au vieillissement, qui constitue l’évolution habituelle du cristallin après l’âge de 65 ans. Certaines cataractes apparaissent plus précocement, d’autres plus tardivement, mais 2/3 des personnes en sont atteintes après 80 ans et 100% le seraient après 100 ans ! Les autres causes de cataracte sont variées.\n

    Citons parmi les plus fréquentes :\n

    - les cataractes traumatiques, unilatérales (choc reçu sur l’oeil entraînant une opacification cristallinienne rapide)\n
    - les cataractes cortisoniques, liées à la prise prolongée ou en fortes quantités de corticoïdes (cortisone) par voie générale ou locale.-les cataractes congénitales, de découverte précoce chez le jeune enfant.\n
    - les cataractes métaboliques, dominées par le diabète.-les cataractes liées à une inflammation intra-oculaire prolongée ou mal contrôlée et récidivante (uvéite).\n`
}

export const SoleilOeil: TemplateArticle = {
    id: 9,
    title: "LE SOLEIL ET SES RISQUES SUR L'OEIL",
    image: "assets/img/learn/girl_eye.jpg",
    synopsis: "Il est désormais admis que la plupart des cancers de la peau sont causés par un excès d'exposition au rayonnement solaire ultraviolet (mélanomes, carcinomes cutanés). Les risques du soleil sur les yeux sont moins connus et posent les questions suivantes : quelles parties de l'oeil peuvent être endommagées? Comment protéger ses yeux efficacement des UV? Quels sont les différences de filtration entre les différentes lunettes de soleil?",
    content: `LES UV ET LES YEUX\n
    Les rayons ultraviolets représentent un risque pour 3 composants de l'oeil : la cornée, le cristallin et la rétine. Ses dégâts sont insidieux car ils ne provoquent pas de gêne immédiate.\n

    La cornée est la lentille transparente la plus superficielle de l'oeil, constituée de cellules appelées kératocytes similaires à celles qui constituent notre peau. Et comme la peau, elle peut prendre de véritables "coups de soleil" : en cas de forte exposition à des rayons UV, la cornée peut brûler! C'est le cas en haute montagne où les rayons solaires sont plus intenses (et réfléchis sur la neige), ou lors des soudures à arc ('coups d'arc') entreprises sans lunettes de protection. Cette brûlure sur la cornée s'appelle une kératite, qui peut aller de quelques érosions superficielles de la taille d'une pointe d'épingle à des ulcérations plus diffuses, parfois sur toute la cornée. La kératite est très douloureuse, donnant l'impression d'avoir des grains de sable ou des petits cailloux dans les yeux, et cause souvent une baisse de la vision et une hypersensibilité à la lumière. Heureusement, ces symptômes sont dans la majorité des cas réversibles avec un traitement cicatrisant (gouttes épaisses et pommade à la vitamine A) qui permet la reformation de cellules cornéennes saines par dessus les zones érodées. On appelle ce phénomène la réépithélialisation (reformation d'épithélium).\n

    Le cristallin est la lentille située derrière l'iris : après la cornée, c'est lui qui focalise les rayons lumineux sur la rétine. Transparent chez les personnes jeunes, le cristallin vieillit et s'opacifie avec l'âge jusqu'à devenir opaque autour de 70 ans. On parle alors de cataracte. L'effet des rayons UV sur les protéines du cristallin est modeste, mais une exposition solaire très prolongée au cours de la vie peut accélérer leur vieillissement, donc entraîner des cataractes plus précoces de quelques années. Leur traitement passe toujours par la chirurgie, qui consiste à retirer le cristallin opaque et à le remplacer par une lentille transparente. La cataracte est la première cause de cécité dans le monde car elle n'est pas ou très peu opérée dans les pays sous-développés ou en voie de développement. En France et dans les pays développés, elle n'est plus une cause de cécité mais représente un coût financier majeur puisqu'il s'agit de l'opération la plus pratiquée, de l'ordre de 500.000 interventions chirurgicales par an.\n

    La rétine peut enfin être sévèrement brûlée par les rayons infrarouges, comme le mettent toujours en avant les campagnes de prévention primaire avant une éclipse solaire, dont la contemplation sans protection adéquate est à risque de brûlures irréversibles sur la partie centrale de la rétine, appelée macula. L'exposition intense et prolongée de la rétine aux UV est également considérée comme un facteur de risque de dégénérescence maculaire liée à l'âge (DMLA).\n


    LES PROTECTIONS\n

    Chapeaux, casquettes et surtout lunettes de soleil filtrantes sont les méthodes les plus recommandées pour protéger nos yeux des effets du soleil. Deux éléments sont plus particulièrement à considérer pour le choix de ces dernières :\n

    Pouvoir filtrant des verres : il se classe de 0 à 4 (conformément à la directive européenne de 1996), mais seules les catégories 3 et 4 sont recommandées (3 pour une utilisation courante et 4 pour des conditions extrêmes comme l'alpinisme, la navigation en mer, etc). Il est très important de veiller à la mention du marquage CE suivi de la catégorie de protection lors de l'achat d'une paire de lunettes de soleil. En effet, les lunettes qui ne répondent pas à cette norme s'avèrent dangereuses : la teinte du verre assombrit une partie de la lumière visible sans filtrer les ultraviolets, les pupilles qui ne sont plus éblouies se dilatent, comme dans la pénombre, pour laisser entrer plus de lumière jusqu'à la rétine, dont les UV non filtrés causant des dégâts parfois irréparables. Un peu comme si l'on posait un verre teinté devant l'objectif d'un appareil photo pleinement exposé dont le diaphragme, grand ouvert, laisserait passer les UV y brûler progressivement sa pellicule. A l'inverse, les verres solaires efficaces filtrent à la fois les UV (invisibles pour nos yeux) et la lumière visible dans des proportions sécurisées. Attention donc au marquage CE et à la catégorie de filtration.\n

    Forme de la monture : des études américaines et japonaises ont mis en évidence l'insuffisance de protection de certaines montures non recouvrantes. En tolérant un espace largement ouvert sur les côtés, elles laissent passer une grande proportion d'ultraviolets. Les montures de forme plus enveloppante sont donc recommandées, jusqu'aux traditionnelles lunettes de glacier à protections latérales.\n


    EN RÉSUMÉ\n

    - Les rayons UV du soleil peuvent endommager : la cornée (kératite), le cristallin (cataracte), et la rétine (brûlures maculaires).\n
    - Les lunettes de soleil efficaces doivent être : homologuées CE avec verres filtrants de classe 3 ou 4 aux montures suffisamment recouvrantes sur les côtés.\n
    - La meilleure prévention consiste à ne pas trop s'exposer.\n

    Références iconographiques : The Virgin Suicides (Sofia Coppola, 1999, Paramount Classics, Pathé)\n`
}

export const Myopie: TemplateArticle = {
    id: 10,
    title: "MYOPIE",
    image: "assets/img/learn/myope.jpg",
    synopsis: "La myopie est une anomalie de la vision due à un oeil trop long, responsable d'une vision floue de loin sans lunettes. L'oeil myope est dit trop convergent ou trop puissant. Cet excès de puissance optique fait focaliser l’image d’un objet éloigné en avant de la rétine.",
    content: `l’oeil myope voit net à une distance finie : son punctum remotum, point le plus éloigné auquel il voit net sans accommoder, est situé à une distance “d”.\n

    Plus cette distance est courte (ou plus le punctum remotum est proche), plus la myopie est importante.\n
    Il est ainsi possible d’exprimer le degré de myopie (en dioptries négatives) en fonction de la distance au punctum remotum (en mètres) : Myopie (D) = 1 / d (m)\n
    Par exemple, un oeil qui ne voit pas net au delà de 50 cm (punctum remotum à 0,5m) est myope de -2 dioptries (1 / 0,5 m) . Ou encore, un oeil myope de -10 dioptries ne voit pas net au delà de 10 cm ! (1/10 m)\n
    Ce degré de myopie correspond aussi à la puissance du verre correcteur nécessaire pour lui donner une vision nette de loin: l’oeil myope étant trop convergent (ou trop puissant), ce sont logiquement des verres divergents, de puissance sphérique négative, qui permettent de quantifier l’importance d’une myopie.\n

    De nombreux patients confondent leur degré de myopie (en dioptries négatives, D) et leur acuité visuelle de loin, exprimée en dixièmes, qui mesure la “performance visuelle” avec ou sans correction ! Si cette-dernière est limitée chez un myope sans lunettes dont la vision de loin est floue (par exemple une acuité visuelle de 2/10e sans correction), l’oeil est pourtant capable d’avoir un “score” de 10/10e s’il est corrigé par le verre correcteur adapté : ce même patient, limité à une acuité de 2/10e sans correction, devrait donc remonter à l’acuité de “10/10e“corrigé avec un verre de -1,25 par exemple.\n
    Pour comprendre la différence, on peut imaginer un golfeur capable d’atteindre un drapeau situé à 150 m avec un fer 7, alors qu’un autre golfeur moins puissant atteindra cette même distance avec un fer 5 : le numéro du club (7 ou 5) peut être assimilé à la puissance du verre correcteur nécessaire (-7 ou -5 dioptries) pour atteindre le score voulu (150 m dans l’exemple au golf ou 10/10e en ophtalmologie).\n`
}

export const AlgieVasculaireFace: TemplateArticle = {
    id: 11,
    title: "ALGIE VASCULAIRE DE LA FACE",
    image: "assets/img/learn/algie_vasculaire_face.jpeg",
    synopsis: "L'algie vasculaire de la face est une catégorie de maux de tête, avec des salves (ou crises) de douleurs intenses et brèves (1/4 d'heure à 3 heures) séparées par des intervalles libres sans douleur (de quelques mois à quelques années). La douleur, toujours du même côté, se situe surtout autour de l'œil et s'accompagne volontiers de sueurs, larmes, écoulement du nez ou gonflement des paupières.",
    content: `L'algie vasculaire de la face (AVF) est une catégorie de maux de tête, caractérisée par des salves (ou crises) de douleurs intenses et brèves (1/4 d'heure à 3 heures) séparées par des intervalles libres sans douleur (de quelques mois à quelques années). Les périodes douloureuses peuvent durer de 2 à 8 semaines, avec des crises la plupart du temps pluriquotidiennes (2 à 3 fois par jour), souvent à saison fixe, à heure fixe et volontiers durant la nuit.\n

    La douleur est toujours du même coté, pendant la crise et le plus souvent toujours du même côté (autour du même œil) d'une crise à l'autre. Cette douleur est très intense, évoquant un broiement ou une brûlure atroce. Elle est maximale autour de l'œil mais peut parfois irradier jusque dans l'épaule. Du même côté du visage, la douleur s'accompagne typiquement de sueurs, d'un œil qui pleure (larmoiement), parfois rouge, avec le nez qui coule, et parfois la paupière tombante ou gonflée.\n

    C'est une maladie rare (0,5% de la population) qui touche plutôt des hommes jeunes, débutant généralement entre 10 et 30 ans. Le diagnostic se fait en interrogeant le patient, dont l'examen clinique et les examens complémentaires sont toujours normaux entre les crises.\n`
}

export const Conjonctivite: TemplateArticle = {
    id: 12,
    title: "CONJONCTIVITE",
    image: "assets/img/learn/conjonctivite.jpg",
    synopsis: `Une conjonctivite est une maladie très courante et bénigne de la surface de l'œil. Aussi appelée "pink eye", elle désigne l'inflammation de la conjonctive (la fine membrane recouvrant le blanc de l'œil et sous les paupières. Le plus souvent, elle est due à une infection (virale ou bactérienne), ou à une allergie.`,
    content: `CAUSES\n

    La cause la plus fréquente est une infection. Même si elles ne sont pas graves, les conjonctivites virales sont des pathologies hautement contagieuses, généralement épidémiques en hiver, dont l'agent le plus fréquent est un virus appelé "Adenovirus". Ce virus se transmit par contact avec une personne infectée (serrage de mains, ou contact avec des objets contaminés par le virus, avant de se frotter l'œil). L'infection se propage volontiers d'un œil à l'autre, pouvant souvent toucher les deux yeux dans les premiers jours des symptômes. Une surinfection bactérienne est toujours possible. Ces conjonctivites infectieuses sont hautement contagieuses, à l'opposé des conjonctivites allergiques, le plus souvent bilatérales avec écoulement de larmes claires, touchant les personnes prédisposées avec un terrain allergique (rhinite ou "rhume des foins", urticaire, asthme) et généralement saisonnières (avec un périodicité maximale au printemps).\n

    SYMPTÔMES\n

    Dans sa forme classique, une conjonctivite associe un ou plusieurs des signes suivants :\n

    Rougeur modérée de l'œil (due à l'inflammation de la conjonctive), de couleur typiquement rosée.\n
    Larmoiement clair (larmes propres) ou sale (sécrétions, collant souvent les cils et les paupières le matin au réveil)\n
    Sensation de corps étranger, de "grain de sable" dans l'œil ou sous les paupières, ou de sécheresse (sensation d'irritation différente d'une douleur véritable)\n
    Gonflement des paupières\n

    EVOLUTION / SÉVÉRITÉ\n

    Dans la grande majorité des cas, une conjonctivite est totalement bénigne, avec retour complet à la normale en une à deux semaines (parfois, surtout pour les conjonctivites allergiques, cette période peut être plus longue). Cependant, certaines complications très rares peuvent persister suite une conjonctivite sévère : des opacités cornéennes résistantes après une infection à Adenovirus (karate-conjonctivite), ou une sécheresse résiduelle une fois l'inflammation résolue. En réalité, les récidives constituent la complication principale, surtout chez les jeunes enfants dans les crèches, d'où ils doivent être retirés en cas de conjonctivite infectieuse avant de propager le virus à tous les autres enfants.\n`
}

export const Covid19: TemplateArticle = {
    id: 13,
    title: "COVID-19",
    image: "assets/img/learn/fievre.jpeg",
    synopsis: "Le Covid-19 est le nom de la maladie respiratoire due au Coronavirus Sars-CoV-2, responsable de la pandémie actuelle. Sans signe de gravité, reposez-vous à domicile, buvez de l'eau, et attendez que les symptômes passent (fièvre, toux, mal de tête, courbatures) tout en respectant les mesures de confinement, en particulier si vous êtes fragiles (âgés, obèses, hypertendus, fumeurs ou avec maladies chroniques associées).",
    content: `RESTEZ  CONFINÉS\n

    NE RENDEZ PAS VISITE AUX PERSONNES AGEES ou DANS UN ETAT FRAGILE\n

    LAVEZ-VOUS BIEN LES MAINS, TRES SOUVENT\n

    TOUSSEZ ou ETERNUEZ DANS VOTRE COUDE\n

    UTILISEZ DES MOUCHOIRS JETABLES (à usage unique = à jeter après chaque mouchage)\n

    NE TOUCHEZ PAS D'OBJECTS DES PARTIES COMMUNES (boutons d'ascenseur, poignée de porte d'immeuble, rampe d'escalier...) et LAVEZ QUOTIDIENNEMENT les surfaces fréquemment touchées (poignées, téléphones...)\n

    CONTINUEZ A PRENDRE VOS MEDICAMENTS HABITUELS\n

    SAUF LES ANTI-INFLAMMATOIRES (Ibuprofène, Flurbiprofène, Kétoprofène, Aspirine à dose anti-inflammatoire...)\n

    -> Demandez conseil à votre médecin par appel téléphonique ou téléconsultation\n

    EN CAS DE FIEVRE > 38°C MAL TOLÉRÉE, vous pouvez prendre du Paracétamol (Doliprane, Efferalgan) dans la limite d'1g 3 fois par jour, MAIS PAS D'ANTI-INFLAMMATOIRES NON STEROIDIENS (Advil, Nurofen, Antadys, Aspirine...)\n

    EN CAS DE SIGNE DE DETRESSE RESPIRATOIRE AIGUË (essoufflement à la marche, parole coupée par la respiration, ou impression de manquer d'air) ➭ APPELEZ IMMEDIATEMENT LE 15 (SAMU)\n`
}

export const listArticle : TemplateArticle[] = [
    LesHepatites,
    IntoleranceGluten,
    TumeurPancreas,
    AlgieVasculaire,
    RincageOculaire,
    Chalazion,
    Cataracte,
    SoleilOeil,
    Myopie,
    AlgieVasculaireFace,
    Conjonctivite,
    Covid19
]
