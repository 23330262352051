<hr width="50%" size="20px" color="yellow">
<div id="app">
    <div id="intro">
        <img src="{{ image }}" width="250" height="150"/>
        <div id="synopsis">
            <p>{{ title }}</p>
            <p> {{ synopsis }}</p>
        </div>
        <hr width="50%">
    </div>
    <div id="content">
        <p style="white-space: pre;">{{ content }}</p>
    </div>
    <hr width="50%">
</div>