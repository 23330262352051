/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { FormulairesService } from '../../../services/formulaires/formulaires.service';
import { SymptomesService } from '../../../services/symptomes/symptomes.service';
import { RedirectionsService } from '../../../services/redirections/redirections.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-formulaires',
  templateUrl: './formulaires.component.html',
  styleUrls: ['./formulaires.component.css'],
  providers: [DatePipe]

})
export class FormulairesComponent implements OnInit {

  formulaire = {
    _id: null,
    name: '',
    folder: '',
    question: '',
    pictures: [],
    first: false,
    multipleChoice : false,
    templateNumber :2
  }
  first = {
    id : Date.now()
  }


  urlPicture = null;
  excludedSelected = false;

  symptomes = [];
  symptomesFront = [];
  //symptomesHover = [] ;

  redirections = [];
  anwsers = [];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formualiresService: FormulairesService,
    private _symptomesService: SymptomesService,
    private _redirectionsService: RedirectionsService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    let formulaireId = '';
    if (this._activatedRoute.snapshot.params.formulaireId !== undefined) {
      formulaireId = this._activatedRoute.snapshot.params.formulaireId;
      console.log(this._activatedRoute.snapshot);
      /*if(localStorage.getItem(formulaireId) !== null) {
        this.anwsers = JSON.parse(localStorage.getItem(formulaireId));
        console.log(this.anwsers);
      }*/
    }
    else {
      if(localStorage.getItem('previous') !== null){
          let removeItem = [] ;
          for (let i = 0; i < localStorage.length; i++) removeItem.push(localStorage.key(i));
          for (let i = 0; i < removeItem.length; i++)   if(removeItem[i]!=='token') localStorage.removeItem(removeItem[i]);
      }
    }



    this._formualiresService.getFormulaire(formulaireId)
    .subscribe(
      formulaire => {
        if (formulaire.length > 1) {
          this.formulaire = formulaire[0];
          for (let id in formulaire){
            if (formulaire[id]._id === String(formulaireId)){
                this.formulaire = formulaire[id];
                break;
            }
          }
        } else {
          this.formulaire = formulaire;
        }
        if(localStorage.getItem(formulaireId) !== null && this.formulaire.templateNumber !=6 ) {
          this.anwsers = JSON.parse(localStorage.getItem(formulaireId));
          console.log(this.anwsers);
          }
        for(let i =0 ; i< this.formulaire.pictures.length ; i++){
          this.formulaire.pictures[i] = "https://api-icare.overconsulting.net/"+this.formulaire.pictures[i] ;
        }
        //localStorage.setItem('template', ''+this.formulaire.templateNumber );

        // console.log(this.formulaire);
        this._symptomesService.getSymptomes(this.formulaire._id).subscribe(
          symptomes => {
            for(let symptome of symptomes){
              symptome.value=JSON.parse(localStorage.getItem(symptome._id));
              this.symptomes[symptome._id] = symptome;
              let sHover = symptome ;
              sHover.hover = false
              this.symptomesFront.push(sHover);
              //this.symptomesHover[symptome._id] = false;
              if(this.symptomes[symptome._id].picture !=='') this.symptomes[symptome._id].picture = "https://api-icare.overconsulting.net/"+this.symptomes[symptome._id].picture ;
            }
          }
        )

        this._redirectionsService.getRedirections(this.formulaire._id).subscribe(
          redirections => {
            for(let redirection of redirections){
              this.redirections[redirection._id] = redirection;
              //console.log(redirection);
            }
          }
        )

      },
      err => console.log(err)
    )
  }

  select(id,form) {
    console.log('change');
    if(!this.formulaire.multipleChoice){
      this.anwsers = []  ;
      this.anwsers.push(id);
      if(!this.formulaire.first) this.goTo(form);
    }
    else {
      const index: number =  this.anwsers.indexOf(id);
      if (index !== -1) {
        this.anwsers.splice(index, 1);
      } else {
        this.anwsers.push(id);
      }

    }
    localStorage.setItem(form, JSON.stringify(this.anwsers));
    ///console.log($("#femme"))
  }

  findNextNavigate(redirection){
    if (redirection.targetFormulaireId != null){
      this._router.navigateByUrl('/', {skipLocationChange: true})
        .then(()=>this._router.navigate(['formulaires', redirection.targetFormulaireId]))
    } else {
      this._router.navigateByUrl('/', {skipLocationChange: true})
        .then(()=>this._router.navigate(['pages', redirection.targetPageId]));
    }
      }

goTo(formulaireId) {
    this.excludedSelected = false;
    let updatePreviousStorage = false ;
    // Here we test if there is Excluded values
    // TODO: Ajouter un message à l'écran
    //console.log(this.redirections)
    for (let id in this.redirections){
      console.log(this.redirections[id])
      console.log('0');
      if (this.redirections[id].rulesExcluded.length > 0 && this.anwsers.some(
        s => this.redirections[id].rulesExcluded.includes(s)
        )){
          console.log('symptomes incompatibles');
          this.excludedSelected = true;
          return false;
      }
    }

    // Here we check if there is a value in Excluded values
    for (let id in this.redirections){
      console.log(this.redirections[id]);
      if (this.redirections[id].rulesExcluded.length > 1 &&
      this.redirections[id].rulesHard.every(s => this.anwsers.includes(s))){
        this.findNextNavigate(this.redirections[id]);
         updatePreviousStorage = true ;
    }
    }

    let redirectFlag = false ;

    if(!redirectFlag){
      for (let id in this.redirections){
        console.log('1');
        if (this.redirections[id].rulesHard.length > 1 && this.redirections[id].rulesHard.every(s => this.anwsers.includes(s))){
          this.findNextNavigate(this.redirections[id]);
          updatePreviousStorage = true ;
          redirectFlag = true ;
        }
      }
    }

    if(!redirectFlag){
      for (let id in this.redirections){
        console.log('2');
        if (this.redirections[id].rulesHard.length >= 1 && this.redirections[id].rulesHard.some(s => this.anwsers.includes(s))){
          this.findNextNavigate(this.redirections[id]);
          redirectFlag = true ;
          updatePreviousStorage = true ;
        }
      }
    }

    if(!redirectFlag){
      console.log('3');
      let resultIssueDe, isPassed ;
      for (let id in this.redirections){
        resultIssueDe = true ; isPassed = false ;
        for(let idDe in this.redirections[id].issueDe){
            if(this.redirections[id].issueDe[idDe].selectedSymptomes[0].type === 'Free Text'){
              let value = JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id)) ;
              if(value === null) value = 0; //? utilité
              console.log(value);
              let compareSymbole = this.redirections[id].issueDe[idDe].selectedSymptomes[0].compareSymbole ;
              let compareValue = this.redirections[id].issueDe[idDe].selectedSymptomes[0].compareValue ;
              console.log(value+compareSymbole+compareValue);
              resultIssueDe = eval(value+compareSymbole+compareValue) && resultIssueDe ;
              isPassed = true ;
            }
            else {
              console.log('ici');
              try {
                console.log(JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)));
                console.log(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id);
                console.log(JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)).includes(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id));
                resultIssueDe = JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)).includes(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id) && resultIssueDe ;
                isPassed = true ;
              }

              catch(e) {
                console.log('no redirection');
              }
            }
        }
        if (isPassed && resultIssueDe && this.redirections[id].rules.length > 0 && this.redirections[id].rules.every(s => this.anwsers.includes(s))){
              //console.log(this.redirections[id]);
              redirectFlag = true ;
              this.findNextNavigate(this.redirections[id]);
              updatePreviousStorage = true ;
        }
      }
    }


    if(!redirectFlag){
        for (let id in this.redirections){
          console.log('4');
          if (this.redirections[id].rules.length > 0 && this.redirections[id].rules.every(s => this.anwsers.includes(s))){
            this.findNextNavigate(this.redirections[id]);
            redirectFlag = true ;
            updatePreviousStorage = true ;
         }
        }

    }

    if(!redirectFlag){
      console.log('5');
      let resultIssueDe, isPassed ;
      for (let id in this.redirections){
        resultIssueDe = true ; isPassed = false ;
        for(let idDe in this.redirections[id].issueDe){
            if(this.redirections[id].issueDe[idDe].selectedSymptomes[0].type === 'Free Text'){
              let value = JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id)) ;
              if(value === null) value = 0; //? utilité
              console.log(value);
              let compareSymbole = this.redirections[id].issueDe[idDe].selectedSymptomes[0].compareSymbole ;
              let compareValue = this.redirections[id].issueDe[idDe].selectedSymptomes[0].compareValue ;
              console.log(value+compareSymbole+compareValue);
              resultIssueDe = eval(value+compareSymbole+compareValue) && resultIssueDe ;
              isPassed = true ;
            }
            else {
              try {
                console.log('ici');
                console.log(JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)));
                console.log(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id);
                console.log(JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)).includes(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id));
                resultIssueDe = JSON.parse(localStorage.getItem(this.redirections[id].issueDe[idDe].selectedSymptomes[0].formulaireId)).includes(this.redirections[id].issueDe[idDe].selectedSymptomes[0]._id) && resultIssueDe ;
                isPassed = true ;
              }
              catch(e) {
                console.log('no redirection');
              }
            }
        }
        if (isPassed && resultIssueDe && this.redirections[id].rulesExcluded.length == 0 && this.redirections[id].rulesHard.length == 0 && this.redirections[id].rules.length == 0){
              //console.log(this.redirections[id]);
              redirectFlag = true ;
              this.findNextNavigate(this.redirections[id]);
              updatePreviousStorage = true ;
        }
      }
    }


    if(!redirectFlag){
      for (let id in this.redirections){
        console.log('6');
        if (this.redirections[id].rulesExcluded.length == 0 && this.redirections[id].rulesHard.length == 0 && this.redirections[id].rules.length == 0){
          redirectFlag = true ;
          this.findNextNavigate(this.redirections[id]);
           updatePreviousStorage = true ;
      }
      }
    }
    if(updatePreviousStorage){
      if(localStorage.getItem('previous') === null)  localStorage.setItem( 'previous', JSON.stringify([formulaireId]));
      else{
        let previous = JSON.parse(localStorage.getItem('previous'));
        previous.includes(formulaireId) ? '' : previous.push(formulaireId);
        localStorage.setItem( 'previous', JSON.stringify(previous));
      }
    } else {
      alert('redirection non programmée ;-)')
    }

  }
    goBack(formulaireId): void {
    console.log(formulaireId);
    let previous = JSON.parse(localStorage.getItem('previous')).indexOf(formulaireId) ;
    if(previous==-1) previous = JSON.parse(localStorage.getItem('previous'))[JSON.parse(localStorage.getItem('previous')).length-1] ;
    else previous = JSON.parse(localStorage.getItem('previous'))[previous - 1];
    console.log(previous);
    this._router.navigateByUrl('/', {skipLocationChange: true})
          .then(()=>this._router.navigate(['formulaires', previous]));
    /*localStorage.setItem( 'previous', JSON.stringify(previous));
    */
  }

   storeInputFree(sympt,value){
      //this.freeTextResult.push(sympt);
      localStorage.setItem(sympt, JSON.stringify(value));
     // if(!this.formulaire.multipleChoice)goto(form);

    }
    mouseEnter(id){
      this.symptomesFront[id].hover = true;
      console.log(this.symptomesFront[id]);
    }
    mouseLeave(id){
      this.symptomesFront[id].hover = false;
      console.log(this.symptomesFront[id]);
    }

}
