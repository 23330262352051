/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  page = {
    _id: null,
    name: null,
    presentation: null,
    diagnostic: null,
    skill: null,
    folder: null,
    knowMorePageId:null,
    curesTargetPageId: null,
    explanation: null
  }

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _pagesService: PagesService,
  ) { }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.pageId === undefined) {
      this._router.navigate( ['/formulaires']);
    }

    this._pagesService.getPage(this._activatedRoute.snapshot.params.pageId)
    .subscribe(
      page => {
        console.log(page)
        this.page = page;
      },
      (err) => {
        this._router.navigate( ['/formulaires']);
      }
      );
  }

  goTo(pageId) {
    this._router.navigateByUrl('/', {skipLocationChange: true})
      .then(()=>this._router.navigate(['i-learn', pageId]));
  }

}
