import { Component, OnInit, Input} from '@angular/core';
import {ArticleSynopsis} from './article-synopsis/article-synopsis-interface';


@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  @Input() title: string;
  @Input() listArticle: ArticleSynopsis[];

  constructor() { }

  show : Boolean = false;

  showArticles () {
    this.show = !this.show;
  }

  ngOnInit(): void {
  }

}
