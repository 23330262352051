<header>

    <div *ngIf="formulaire.templateNumber==2" class="container">
      <br>      
      <div class="row">
        <div class="col-6 block-container-picture-template-1">
          <p class="text-center " *ngFor="let picture of formulaire.pictures">
            <img [src]=picture  class="img-form-template-3" *ngIf="picture" />
          </p>
        </div>
      <div class="col-6">
          <p class="ic-question">
            {{ formulaire.question }}
          </p>
          <div  class="block-container-anwser-template-1">
            <div class="block-anwser-template-1" *ngFor="let symptome of symptomesFront | keyvalue; index as i;">
            <!--<p>-->
              <button  *ngIf="symptome.value.type=='Read Only'" type="button" (click)="select(symptome.value._id,formulaire._id);" [ngClass]="(anwsers.includes(symptome.value._id)) ? 'active' : '' " class="btn btn-outline-dark t2">{{ symptome.value.label }}</button>
              <p *ngIf="symptome.value.type=='Free Text'" class="ic-freetext">
                {{ symptome.value.label }} 
              </p>
              <p *ngIf="symptome.value.type=='Free Text'" >
               
                  <input type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
                  <label *ngIf="false" class="ic-symptome-anwser" >   A COMPL </label>
              </p>
          </div>
        </div>
        </div>
      </div>
      
    <hr>
    <div class="col-12">
      <p class="text-right">
        <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
        <button  [disabled]="! anwsers.length" [ngClass]="{'btn next': anwsers.length}" (click)="goTo(formulaire._id)" >Suivant >></button>
      </p>
    </div>
  </div>
    
  <div *ngIf="formulaire.templateNumber==6" class="container">
    <br>      
    <div class="row">
      <div class="col-6 block-container-picture-template-1">
        <p class="text-center " *ngFor="let picture of formulaire.pictures">
          <img [src]=picture class="img-form-template-3" *ngIf="picture" />
        </p>
      </div>
    <div class="col-6">
        <p class="ic-question">
          {{ formulaire.question }}
        </p>
        <div  class="block-container-anwser-template-1">
          <div class="block-anwser-template-1" *ngFor="let symptome of symptomesFront | keyvalue; index as i;">
          <!--<p>-->
            <button  *ngIf="symptome.value.type=='Read Only'" type="button" (click)="select(symptome.value._id,formulaire._id);" [ngClass]="(anwsers.includes(symptome.value._id)) ? 'active' : '' " class="btn btn-outline-dark t2">{{ symptome.value.label }}</button>
            <p *ngIf="symptome.value.type=='Free Text'" class="ic-freetext">
              {{ symptome.value.label }} 
            </p>
            <p *ngIf="symptome.value.type=='Free Text'" >
             
                <input type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
                <label *ngIf="false" class="ic-symptome-anwser" >   A COMPL </label>
            </p>
        </div>
      </div>
      </div>
    </div>
  <hr>
  <div class="col-12">
    <p class="text-right">
      <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
      <button class="btn next" (click)="goTo(formulaire._id)" >Suivant >></button>
    </p>
  </div>
</div>


<div *ngIf="formulaire.templateNumber==3" class="container">
      <br>      
      <div class="row">
        <div  class="col-6">
          <p class="text-center" *ngFor="let picture of formulaire.pictures">
            <img [src]=picture class="img-form-template-3" *ngIf="picture" />
          </p>
        </div>
        <div class="col-6">
          <p class="ic-question">
            {{ formulaire.question }}
          </p>
          <!--<div  class="block-container-anwser">-->
            <!--<div class="col6" >-->
            <!--<p>-->
              <div class="row" *ngFor="let symptome of symptomesFront | keyvalue; index as i;">
                
              <div class='col12 col-md-4 pictureSymptom'>
                <img [src]=symptome.value.picture  (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"  *ngIf="symptome.value.type=='Read Only' && symptome.value.picture!=='' " class="img-form-template-2"(click)="select(symptome.value._id,formulaire._id)" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'img-off' : 'img-on' "/>
              </div>
              <div class ='col12 col-md-8 pictureSymptom' *ngIf="symptome.value.type=='Read Only'">
                <button type="button" (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"  (click)="select(symptome.value._id,formulaire._id);" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'active' : '' " class="btn btn-outline-dark t3">{{ symptome.value.label }}</button>
              </div>
            </div>
              <!--<div class ='symptom' *ngIf="symptome.value.type=='Free Text'" >
                <label class="ic-freetext">
                  {{ symptome.value.label }} 
                 </label>
                <input type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
               
            </div>-->
          <!--</div>-->
        <!--</div>-->
        </div>
      </div>
      <div class="row">
        <div  class="col-6">
          
        </div>
        <div class="col-6">  
          
        </div>    
    </div>
    <hr>
    <div class="col-12">
      <p class="text-right">
        <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
        <button  [disabled]="! anwsers.length" [ngClass]="{'btn next': anwsers.length}" (click)="goTo(formulaire._id)" >Suivant >></button>
      </p>
    </div>
  </div>
    

<div *ngIf="formulaire.templateNumber==4" class="container">
      <br>      
      <div class="row">
        <div class="col-6">
          <p class="ic-question">
            {{ formulaire.question }}
          </p>
        </div>
        <div  class="col-6">
          <div class="row" *ngFor="let symptome of symptomesFront | keyvalue; index as i;">
            <!--<p>-->
              <div class='col12 col-md-4 pictureSymptom'>
                <img [src]=symptome.value.picture (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"   *ngIf="symptome.value.type=='Read Only' && symptome.value.picture!=='' " class="img-form-template-2"(click)="select(symptome.value._id,formulaire._id)" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'img-off' : 'img-on' " />
              </div>
              <div class ='col12 col-md-8 pictureSymptom' *ngIf="symptome.value.type=='Read Only'">
                <button type="button" (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"  (click)="select(symptome.value._id,formulaire._id);" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'active' : '' " class="btn btn-outline-dark t3">{{ symptome.value.label }}</button>
              </div>
              
              <!--<div class ='symptom' *ngIf="symptome.value.type=='Free Text'" >
                <label class="ic-freetext">
                  {{ symptome.value.label }} 
                </label>
                <input type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
                
            </div>-->
          
        </div>
      </div>
      
        </div>
     
    <hr>
    <div class="col-12">
      <p class="text-right">
        <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
        <button  [disabled]="! anwsers.length" [ngClass]="{'btn next': anwsers.length}" (click)="goTo(formulaire._id)" >Suivant >></button>
      </p>
    </div>
  </div>



<div *ngIf="formulaire.templateNumber==5" class="container ">
      <br>      
      <div class="row">
        <div  class="col-12">
          <p class="ic-question">
            {{ formulaire.question }}
          </p>
        </div>
      </div>          
        <div *ngFor="let symptome of symptomesFront | keyvalue; index as i;">
          <div class="row ">

            <div class='col-3' *ngFor="let symptome of symptomesFront | keyvalue | slice:i*4:(i+1)*4 ; index as j;">
                <div class='block-anwser-template4'>
                  <p><img  [src]=symptome.value.picture  (mouseenter)="mouseEnter(i*4+j) " (mouseleave) ="mouseLeave(i*4+j)"  *ngIf="symptome.value.type=='Read Only'" class="img-form-template-4" (click)="select(symptome.value._id,formulaire._id)" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'img-off' : 'img-on' " /></p>
                </div>
            </div>
          </div>
          <div class="row ">
            <div class='col-3' *ngFor="let symptome of symptomesFront | keyvalue | slice:i*4:(i+1)*4 ; index as j;">
                <div class='block-anwser-template4'>
                  <ng-template [ngIf]="symptome.value.type=='Read Only'">
                    <br><br>
                  </ng-template>
                  <button *ngIf="symptome.value.type=='Read Only'" type="button" (mouseenter)="mouseEnter(i*4+j) " (mouseleave) ="mouseLeave(i*4+j)"  (click)="select(symptome.value._id,formulaire._id);" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'active' : '' " class="btn btn-outline-dark t5">{{ symptome.value.label }}</button>
                  <p *ngIf="symptome.value.type=='Free Text'" class="ic-freetext"> {{ symptome.value.label }} </p>
                  <input *ngIf="symptome.value.type=='Free Text'" type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
                  
                </div>
            </div>
          </div>
          <br>
        </div>
    <hr>
    <div class="col-12">
      <p class="text-right">
        <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
        <button  [disabled]="! anwsers.length" [ngClass]="{'btn next': anwsers.length}" (click)="goTo(formulaire._id)" >Suivant >></button>
      </p>
    </div>
  </div>



  <div *ngIf="formulaire.templateNumber==1" class="container">
    <br>
          
            <div class="row">
                <div class="col-3" *ngFor="let symptome of symptomesFront  | keyvalue | slice:0:2 ;index as i ;">    
                        <p class="text-center block-anwser-template5">
                          <img [src]=symptome.value.picture  *ngIf="symptome.value.picture"  (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"  (click)="select(symptome.value._id,formulaire._id)" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'img-off' : 'img-on' ">
                        </p>
                </div>
                <ng-template *ngFor="let symptome of symptomesFront | keyvalue ; index as i " [ngIf]="i>1"  >
                  <div  class="block-container-first-sub">
                    <div class="block-anwser-first-sub">
                      <p class="text-center ic-freetext">
                        {{ symptome.value.label }} 
                      </p>
                      <p *ngIf="symptome.value.type=='Free Text'" class="text-center"> 
                        <input type="number" name="symptome-type[{{i}}]" id="select-symptome-[{{i}}]" [(ngModel)]="symptome.value.value" class="ic-symptome-edit" (change)="storeInputFree(symptome.value._id,symptome.value.value);" ><br>
                        <label *ngIf="false" class="ic-symptome-anwser" >   A COMPL </label>
                      </p>
                                    
                    </div>
                   </div>
                  </ng-template>
            </div>
            <div class='row'>
              <div class="col-6">
                <p class="text-center ic-question-first">
                  {{ formulaire.question }}
                </p>
              </div>
            </div>
            <div class="row">
                  <div  class="col-3 block-anwser-first"  *ngFor="let symptome of symptomesFront | keyvalue | slice:0:2 ; index as i">
                    <p class="text-center">
                        <button type="button" (mouseenter)="mouseEnter(i) " (mouseleave) ="mouseLeave(i)"  (click)="select(symptome.value._id,formulaire._id)" [ngClass]="( (anwsers.includes(symptome.value._id))||(symptome.value.hover)) ? 'active' : '' "  class="btn btn-outline-dark">{{ symptome.value.label }}</button> 
                    </p>
                  </div>
              
            </div>
         
          <div class="row">
          
            <!--<div class="col-6">
              <br>
              <p class="text-center ic-question">
                
              </p>
              
            </div>-->
      </div>
      <hr>
      <div class="col-12">
        <p class="text-right">
          <button *ngIf="!formulaire.first" (click)="goBack(formulaire._id);" class="btn prev" ><< Précédent</button>
          <button   [disabled]="! anwsers.length" [ngClass]="{'btn next': anwsers.length}" (click)="goTo(formulaire._id)" >Suivant >></button>
        </p>
      </div>
  </div>
  
  <div class="row pt-5">
    <div class="col-12">
      <p class="text-right">
        <span [ngClass]="{'alert alert-danger alert-dismissible fade':true, show:excludedSelected }" role="alert">
          Symptômes incompatibles.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </span>
      </p>
    </div>
  </div>
  
</header>
