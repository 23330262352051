/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth/auth.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  redirected = true ;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.redirected = this.authService.isTokenExpired(this.authService.getToken());
    //console.log(this.redirected);
  }

}
