<app-admin-header></app-admin-header>
<div class="container mt-4 mb-4">
    <div class="row">
        <div class="col-2">
            <ul class="nav flex-column nav-pills nav-fill">
                <li class="nav-item">
                    <a routerLink="/admin" class="nav-link">Dashboard</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/admin/idiagnose" routerLinkActive="active" class="nav-link">iDiagnose</a>
                </li>
                <li class="nav-item" *ngFor="let folder of folders">
                  <a [routerLink]="['/admin/idiagnose', folder]" routerLinkActive="active" preserveQueryParams preserveFragment class="nav-link">{{folder}}</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/admin/ilearn" routerLinkActive="active" class="nav-link">iLearn</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/admin/ifind" routerLinkActive="active" class="nav-link">iFind</a>
                </li>
            </ul>
        </div>
        <div class="col-10">
            <app-alert-msg></app-alert-msg>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-admin-footer></app-admin-footer>
