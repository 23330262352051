<div class="row">
<h2>iFind</h2>
</div>
<br>
<div class="row">
    <div class="col-9">
      <h4>Practiciens <a routerLink="/admin/add-practicien" class="btn btn-info btn-sm">+</a></h4>
      <br>
      <br>
      <div *ngIf='nbResult' class='result'>
        <strong>{{nbResult}}</strong><span> spécialistes trouvés</span>
      </div>
      <br>
        
        <a id="anc"></a>
        <div class="col-12">
              <div class="input-group recherche">
                <div class="input-group-prepend ">
                  <select id="oCategorie" name="oCategorie" class="form-control" [(ngModel)]='searchType' >
                    <option value="Tous">Tous</option>
                    <option *ngFor="let skill of skills" [value]="skill"  >{{skill}}</option>  
                  </select>
                </div>
                <input id="oSaisie" name="oSaisie" type="text" class="form-control oSaisie" [(ngModel)]='searchTerm'  placeholder = 'Effectuer une recherche ...' >
                <div class="input-group-append">
                  <button class="btn btn-secondary" (click)='rechercher()'>➤</button>
                </div>
              </div>
        </div>
  
        <br>
        <br>

        <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Spécialité</th>
                </tr>
              </thead>
                <tbody>
                    <tr *ngFor="let practicien of practiciensB ">
                        <td (click)="editPracticien(practicien)"><strong>{{practicien.name}}</strong><br>{{practicien.address}}</td>
                        <td>{{practicien.skill}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
