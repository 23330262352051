/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavksy@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { FormulairesService } from '../../../../services/formulaires/formulaires.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.css']
})
export class AdminAddFormComponent implements OnInit {
  urlPicture = null;
  multipleSelectedFiles = [];
  urlPictures = [];

  postFormulairesData = {
    name: "",
    folder: "",
    question: '',
    picture: '',
    first: false,
    multipleChoice : false,
    templateNumber : 2
  }

  constructor(
    private _formulairesServices: FormulairesService,
    private _router: Router,
    private location: Location
    ) { }

  ngOnInit(): void {
  }

  onMultipleSelectImages(event): void {
    if (event.target.files) {
      this.multipleSelectedFiles = event.target.files;

      let cpt=0;
      for (let img of this.multipleSelectedFiles) {
        var reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload=(event:any)=>{
          this.urlPictures[cpt] = event.target.result;
          cpt++;
        };
      }
    }
  }

  goBack(): void {
    this.location.back();
  }

  onUpload(): void {
    // console.log(this.postFormulairesData)
    const fd = new FormData();

    for (let img of this.multipleSelectedFiles) {
      fd.append('pictures', img, img.name);
    }

    fd.append('name', this.postFormulairesData.name);
    fd.append('folder', this.postFormulairesData.folder);
    fd.append('question', this.postFormulairesData.question);
    fd.append('first', ''+this.postFormulairesData.first);
    fd.append('multipleChoice', ''+this.postFormulairesData.multipleChoice);
    fd.append('templateNumber', ''+this.postFormulairesData.templateNumber);

    // console.log(this.postFormulairesData);
    this._formulairesServices.postFormulaire(null, fd)
    .subscribe(
      res => {
        // console.log(res);
        // alert('Le formulaire a bien été enregistré.');
        this._router.navigate( ['/admin/edit-form/' + res._id]);
      },

      err => {
        if (this.postFormulairesData.name === "" || this.postFormulairesData.folder === "" || this.postFormulairesData.question === "") {
            alert("L'un des champs suivant n'a pas été rempli :\n- Nom du formulaire\n- Dossier\n- Question");
        }
        console.log(err);
      }
    )
  }

}
