<div class="container">
    <div class="row">
        <div class="col-12 pt-4 mb-4">
            <p class="text-center ic-question" [innerHTML]="page.explanation">
            </p>
        </div>
    </div>
    <hr>
   
    <div class="text-center">
    <a class="ic-link" *ngIf="page.knowMorePageId != null">
       <!--<button type="button" class="btn btn-outline-dark" *ngIf="page.knowMorePageId != null" (click)="goTo(page.knowMorePageId)">En savoir plus</button>&nbsp;-->
        <span class="ic-legend">En savoir plus</span>
        <img class="treatment" src="assets/img/diagnose/treatment-icon.png" height="180">
    </a>     
    <a class="ic-link"  (click)="goTo(page.curesTargetPageId)">
        <span class="ic-legend">Voir les traitements</span>
        <img class="learn" src="assets/img/diagnose/i-learn.png" height="180">
    </a>     
    </div>
    <br>
</div>
