/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { FormulairesService } from '../../../../services/formulaires/formulaires.service';
import { PagesService } from '../../../../services/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipeListForm1} from '../../../shared/pipe/filter/filterListForm1.pipe';
import { FilterPipeListForm2} from '../../../shared/pipe/filter/filterListForm2.pipe';

@Component({
  selector: 'app-list-form',
  templateUrl: './list-form.component.html',
  styleUrls: ['./list-form.component.css'],
  providers: [FilterPipeListForm1,FilterPipeListForm2]
})
export class AdminListFormComponent implements OnInit {

  formulaires = [];
  pages = []
  searchTermName1: string ;
  searchTermFolder1: string ;
  sortReverse1 = false ;
  sortType1 = 'name' ;
  searchTermName2: string ;
  searchTermFolder2: string ;
  sortReverse2 = false ;
  sortType2 = 'name' ;

  constructor(
    private _formulairesService: FormulairesService,
    private _pagesService: PagesService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._formulairesService.getFormulaires()
    .subscribe(
      formulaires => {
        this.formulaires = formulaires;
        // console.log(this.formulaires);
      },
      err => console.log(err)
    )

    this._pagesService.getPages()
    .subscribe(
      pages => {
        this.pages = pages;
        // console.log(this.pages);
      },
      err => console.log(err)
    )
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(
      params => {
        let folder = params['folder'];
        if (this._activatedRoute.snapshot.params.folder != undefined) {
          this.searchTermFolder1 = folder;
          this.searchTermFolder2 = folder;
        } else {
          this.searchTermFolder1 = "";
          this.searchTermFolder2 = "";
        }
      });
  }

  editForm(formulaire) {
    // console.log(formulaire);
    this._router.navigate( ['/admin/edit-form/'+formulaire._id]);
  }

  editPage(page) {
    // console.log(page);
    this._router.navigate( ['/admin/edit-page/'+page._id]);
  }

}
