/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormulairesService {

  private _url = environment.apiBaseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private router: Router) { }

  getFormulaires() {
    return this.http.get<any>(this._url + "/formulaires")
  }

  getFolders() {
    return this.http.get<any>(this._url + "/formulaires/folder")
  }

  getFormulaire(id) {
    if (id == "") {
      return this.getFirst()
    } else {
      return this.http.get<any>(this._url + "/formulaires/"+id)
    }
  }

  getFirst() {
    return this.http.get<any>(this._url + "/formulaires/first")
  }

  postFormulaire(id, formulaire) {
    if (id != null) {
      return this.http.patch<any>(this._url + "/formulaires/"+id, formulaire);
    } else {
      return this.http.post<any>(this._url + "/formulaires", formulaire);
    }
  }

  uploadPicture(id, formulaire) {
    return this.http.post<any>(this._url + "/formulaires/"+id+"/upload-picture", formulaire);
  }

  deleteFormulairePicture(FomulaireId, pictureNb) {
    return this.http.delete<any>(this._url + "/formulaires/"+FomulaireId+"/picture/"+pictureNb)
  }

  deleteFormulaire(id) {
    return this.http.delete<any>(this._url + "/formulaires/"+id)
  }
}
