/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { FormulairesService } from '../../../../services/formulaires/formulaires.service';
import { PagesService } from '../../../../services/pages/pages.service';
import { SymptomesService } from '../../../../services/symptomes/symptomes.service';
import { RedirectionsService } from '../../../../services/redirections/redirections.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipeEditForm1} from '../../../shared/pipe/filter/filterEditForm1.pipe';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-admin-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.css'],
  providers: [FilterPipeEditForm1]
})

export class AdminEditFormComponent implements OnInit {
  svrUrl = environment.apiBaseUrl + "/";
  validatedForm = false;
  errorForm = false;
  searchTermName1: string ;
  searchTermFolder1: string ;
  sortReverse1 = false ;
  sortType1 = 'name' ;
  searchTermName2: string ;
  searchTermFolder2: string ;
  sortReverse2 = false ;
  sortType2 = 'name' ;
  multipleSelectedFiles = [];
  urlPictures = [];
  formPictures = [];
  newSymptomeUrl = null;
  selectedFile = null;
  sympPictures = [];
  selectedFilesSymptome = [];

  symptomes = [];
  symptomesDrag = [];
  allSymptomes = [];
  formulaires = [];
  pages = [];
  redirections = [];
  redirectionsDrag = [];
  selectedSymptomes = [];

  // Data to add new symptomes
  newSymptome = {
    _id: null,
    label: "",
    type: "Read Only",
    value: "",
    formulaireId: this._activatedRoute.snapshot.params.id,
    order : 1,
    picture: ""
  }

  // Data of the formulaire
  postFormulairesData = {
    _id: null,
    name: "",
    folder: "",
    question: '',
    first: false,
    pictures: [],
    multipleChoice : false,
    templateNumber :2
  }

  selectedForm = {
    type: null,
    data: {
      _id: null,
      name: "",
      folder: "",
      first: false,
      multipleChoice : false,
      templateNumber : 2
    }
  }

  nextRedirection = {
    _id: null,
    formulaireId: this._activatedRoute.snapshot.params.id,
    rulesExcluded: [],
    rulesHard: [],
    rules: [],
    targetFormulaireId: null,
    targetPageId: null,
    issueDe: [],
    order : 1
  }

  currentIssueDe = {
    active: false,
    sourceForm: "",
    symptomsForm: [],
    selectedSymptomes: []
  }

  currentRedirection = '' ;//edit issue de
  issueDeSymptome = '';//edit issue de
  editIssueDeSymptomeElement = 0 ; //edit issue de
  issueDeMode = false ;           //edit issue de
  issueDeCurse = '';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _formulairesServices: FormulairesService,
    private _pagesServices: PagesService,
    private _symptomesServices: SymptomesService,
    private _redirectionsServices: RedirectionsService,
    private location: Location
  ) {
    let params: any = this._activatedRoute.snapshot.params;
    // console.log(params.id);
    this._formulairesServices.getFormulaire(params.id)
    .subscribe(
      res => {
        // console.log(res);
        this.postFormulairesData = res;
        for (let img of res.pictures) {
          this.formPictures.push(this.svrUrl + img);
        }
      },
      err => {
        console.log(err);
        this._router.navigate(['/admin/idiagnose']);
      }
    )

    this._symptomesServices.getSymptomes(params.id)
    .subscribe(
      symptomes => {
        for(let symptome of symptomes){
          this.symptomes[symptome._id] = symptome;
          this.symptomesDrag.push(symptome);/////// add order
          this.selectedSymptomes.push({_id: symptome._id, label: symptome.label, rule: 0})
          if (symptome.picture != '') {
            this.sympPictures.push(this.svrUrl + symptome.picture);
          } else {
            this.sympPictures.push(null);
          }
          // console.log(symptome);
        }
      },
      err => console.log(err)
    )

    this._formulairesServices.getFormulaires()
    .subscribe(
      formulaires => {
        for(let formulaire of formulaires){
          this.formulaires[formulaire._id] = formulaire;
          //all symptomes
          this._symptomesServices.getSymptomes(formulaire._id)
            .subscribe(
              symptomes => {
                for(let symptome of symptomes){
                  this.allSymptomes[symptome._id] = symptome;
                  // console.log(symptome);
                }
               },
              err => console.log(err)
            )
        }
        this._redirectionsServices.getRedirections(params.id)
          .subscribe(
            redirections => {
              for(let redirection of redirections){
                // console.log(this.redirections.indexOf(redirection._id));
                this.redirections[redirection._id] = redirection;
                this.redirectionsDrag.push(redirection);/////// add order
                for (let el in this.redirections[redirection._id].issueDe) {
                  for(let symptome in this.allSymptomes) {
                    if(this.allSymptomes[symptome]._id  == this.redirections[redirection._id].issueDe[el].selectedSymptomes[0]._id){
                      this.redirections[redirection._id].issueDe[el].selectedSymptomes[0].label = this.allSymptomes[symptome].label;
                      let fd = this.redirections[redirection._id] ;
                      //console.log(fd);
                      this._redirectionsServices.postRedirection(fd).subscribe(
                          res => {/*console.log(res)*/},
                          err => {console.log(err);}
                        )
                    }
                  }
                }
                console.log(this.redirectionsDrag);
               }

            },
            err => console.log(err)
          )
      },
      err => console.log(err)
    )

    this._pagesServices.getPages()
    .subscribe(
      pages => {
        for(let page of pages){
          this.pages[page._id] = page;
        }
        //console.log(this.formulaires);
      },
      err => console.log(err)
    )

  }

  ngOnInit(): void {
  }

  issueDeFormSelected(formId){
    //console.log(formId);
    if (formId != ''){
      this.currentIssueDe.sourceForm = formId;
      this.currentIssueDe.selectedSymptomes = [];
      this.currentIssueDe.symptomsForm = [];
      this._symptomesServices.getSymptomes(formId)
      .subscribe(
        symptomes => {
          // console.log(this.currentIssueDe);
          for(let symptome of symptomes){
            this.currentIssueDe.symptomsForm[symptome._id] = symptome;
          }
          //console.log( this.currentIssueDe);
        },
        err => console.log(err)
      )
    }
  }

  issueDeSymptomSelected(symptomId){

    var symbole : '==';
    var value = null;

    if(this.nextRedirection.issueDe.length>0){
      if(this.nextRedirection.issueDe[this.editIssueDeSymptomeElement].selectedSymptomes[0]._id===symptomId){
        symbole = this.nextRedirection.issueDe[this.editIssueDeSymptomeElement].selectedSymptomes[0].compareSymbole ;
        value = this.nextRedirection.issueDe[this.editIssueDeSymptomeElement].selectedSymptomes[0].compareValue ;
      }
    }
    if(symbole == null) symbole = '==';

    if(symptomId != ''){
      this.currentIssueDe.selectedSymptomes[symptomId] = this.currentIssueDe.symptomsForm[symptomId];
      if (this.currentIssueDe.selectedSymptomes[symptomId].type == "Free Text"){
        this.currentIssueDe.selectedSymptomes[symptomId].compareSymbole = symbole;
        this.currentIssueDe.selectedSymptomes[symptomId].compareValue = value;
      } else {
        this.currentIssueDe.selectedSymptomes[symptomId].compareSymbole = null;
        this.currentIssueDe.selectedSymptomes[symptomId].compareValue = null;
      }
      this.currentIssueDe.selectedSymptomes[symptomId].hard = false;
    }
    console.log(this.currentIssueDe.selectedSymptomes);
  }

  changeCompareSymbole(id){
    if ( this.currentIssueDe.selectedSymptomes[id].compareSymbole == '==') {
      this.currentIssueDe.selectedSymptomes[id].compareSymbole = '<';
    } else if (this.currentIssueDe.selectedSymptomes[id].compareSymbole == '<') {
      this.currentIssueDe.selectedSymptomes[id].compareSymbole = '>';
    } else if (this.currentIssueDe.selectedSymptomes[id].compareSymbole == '>') {
      this.currentIssueDe.selectedSymptomes[id].compareSymbole = '==';
    }
    // console.log(this.currentIssueDe.selectedSymptomes[id].compareSymbole);
    // console.log(this.currentIssueDe.selectedSymptomes[id]);
  }

  deleteIssueDeSymptome(id){
    //console.log(id);
    //console.log(this.currentIssueDe.selectedSymptomes[id]);
    delete this.currentIssueDe.selectedSymptomes[id];
  }

  addIssueDe(){
    this.nextRedirection.issueDe.push({
      sourceForm: this.currentIssueDe.sourceForm,
      selectedSymptomes: []
      //this.currentIssueDe.selectedSymptomes,
    });
    // console.log(this.nextRedirection.issueDe[this.nextRedirection.issueDe.length - 1]);
    for(let test in this.currentIssueDe.selectedSymptomes){
      //console.log(this.currentIssueDe.selectedSymptomes[test]);
      this.nextRedirection.issueDe[this.nextRedirection.issueDe.length - 1].selectedSymptomes.push(this.currentIssueDe.selectedSymptomes[test]);
    }
    //console.log(this.nextRedirection.issueDe);
    this.currentIssueDe = {
       active: false,
       sourceForm: "",
       symptomsForm: [],
       selectedSymptomes: []
     }
   }

   deleteIssueDe(issue){
      //console.log(issue);
     let index = this.nextRedirection.issueDe.indexOf(issue);
     this.nextRedirection.issueDe.splice(index, 1);
     // console.log(this.nextRedirection.issueDe);
   }

   onMultipleSelectImages(event): void {
    if (event.target.files) {
      this.multipleSelectedFiles = event.target.files;

      let cpt=0;
      for (let img of this.multipleSelectedFiles) {
        var reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload=(event:any)=>{
          this.urlPictures[cpt] = event.target.result;
          cpt++;
        };
      }
    }
  }

  onFileSelected(event): void {
    // console.log(event);
    if (event.target.files) {
      this.selectedFile = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(event:any)=>{
        this.newSymptomeUrl = event.target.result;
      };
    }
  }

  onFileChangeSelected(event, index): void {
    // console.log(this.symptomesDrag[index]);
    if (event.target.files) {
      this.selectedFilesSymptome[index] = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(event:any)=>{
        this.sympPictures[index] = event.target.result;
      };
    }
  }

  clickSymptome(i) {
    if(this.selectedSymptomes[i].rule < 3){
      this.selectedSymptomes[i].rule++;
    }
    else {
        this.selectedSymptomes[i].rule = 0;
      }
    //console.log(this.selectedSymptomes[i])
  }


  deleteSymptome(id){
    for (let redirection of this.redirections) {
      if (redirection.rules.indexOf(id) >= 0 || redirection.rulesHard.indexOf(id) >= 0 || redirection.rulesExcluded.indexOf(id) >= 0) {
        alert("Pour supprimer ce symptome, veuillez d'abord supprimer les redirections associées.");
        return false;
      }
    }
    if(confirm("Êtes-vous sûr.e de vouloir supprimer " + this.symptomes[id].label + " ?")) {
      this._symptomesServices.deleteSymptome(id).subscribe(
        res => {
          // console.log(res);
          this.refreshPage();
        },
        err => console.log(err)
      )
    }
  }

  deleteRedirection(redirection) {
    if(confirm("Êtes-vous sûr.e de vouloir supprimer cette redirection ?")) {
      this._redirectionsServices.deleteRedirection(redirection._id)
      .subscribe(
        res => {
          // console.log(res);
          this.redirections.forEach((element, index) => {
            if (element._id == redirection._id) {
              this.redirections.splice(index, 1);
            }
          });
          this.refreshPage();
        },
        err => console.log(err)
      )
    }
  }

  selectForm(id){
    if (this.selectedForm.data._id != id){
      this.selectedForm.data = this.formulaires[id];
      this.selectedForm.type = "form"
    }
    else {
      this.selectedForm.data = {
        _id: null,
        name: "",
        folder: "",
        first: false,
        multipleChoice : false,
        templateNumber :2
      };
      this.selectedForm.type = null;
    }
    this.nextRedirection.issueDe = [] ; //edit issue de
    this.currentIssueDe.active = false ; //edit issue de

  }

  selectPage(id){
    if (this.selectedForm.data._id != id){
      this.selectedForm.data = this.pages[id];
      this.selectedForm.type = "page";
    }
    else {
      this.selectedForm.data = {
        _id: null,
        name: "",
        folder: "",
        first: false,
        multipleChoice : false,
        templateNumber : 2
      };
      this.selectedForm.type = null;
    }
    this.nextRedirection.issueDe = [] ; //edit issue de
    this.currentIssueDe.active = false ; //edit issue de

  }

  changeSymptomes(redirection){
    for(let symptome of this.selectedSymptomes){
      if (redirection.rules.indexOf(symptome._id) >= 0){
        symptome.rule = 1;
      } else if (redirection.rulesHard.indexOf(symptome._id) >= 0){
        symptome.rule = 2;
      } else if (redirection.rulesHard.indexOf(symptome._id) >= 0){
        symptome.rule = 3;
      } else {
        symptome.rule = 0;
      }
    }
  }

  unselectRedirection(){
    for(let symptome of this.selectedSymptomes){
      symptome.rule = 0;
    }
    this.selectedForm.data = {
      _id: null,
      name: "",
      folder: "",
      first: false,
      multipleChoice : false,
      templateNumber :2
    };
    this.selectedForm.type = null;
    this.nextRedirection.issueDe = [] ; //edit issue de
    this.currentIssueDe.active = false ; //edit issue de
  }

  selectRedirection(redirection){
    //console.log(redirection);
    this.currentRedirection = redirection ; //edit issue de
    this.nextRedirection.issueDe = [] ; //edit issue de
    this.currentIssueDe.active = false ; //edit issue de
    this.nextRedirection.issueDe = redirection.issueDe //edit issue de

    if (this.selectedForm.type == 'form'){
      //form is currently selected
      if (redirection.targetFormulaireId != null){
        //redirection clicked is a form
        if (redirection.targetFormulaireId == this.selectedForm.data._id){
          //remove from display the redirection
          this.unselectRedirection();
          return false;
        } else{
          this.selectedForm.type = 'form';
          this.selectedForm.data = this.formulaires[redirection.targetFormulaireId];
          this.changeSymptomes(redirection);
          return false;
        }

      } else if (redirection.targetPageId != null){
        //redirection clicked is a page
        this.selectedForm.type = 'page';
        this.selectedForm.data = this.pages[redirection.targetPageId];
        this.changeSymptomes(redirection);
        return false;
      }

    } else if (this.selectedForm.type == 'page'){
      //page is currently selected
      if (redirection.targetPageId != null){
        //redirection clicked is a page
        if (redirection.targetPageId == this.selectedForm.data._id){
          //remove from display the redirection
          this.unselectRedirection();
          return false;
        } else{
          //replace redirection in display
          this.selectedForm.type = 'page';
          this.selectedForm.data = this.pages[redirection.targetPageId];
          this.changeSymptomes(redirection);
          return false;
        }
      } else if (redirection.targetFormulaireId != null){
        //redirection clicked is a form
        //replace redirection in display
        this.selectedForm.type = 'form';
        this.selectedForm.data = this.formulaires[redirection.targetFormulaireId];
        this.changeSymptomes(redirection);
        return false;
      }
    } else {
      //if nothing currently selected

      if (redirection.targetFormulaireId != null){
        //display selected form
        this.selectedForm.type = 'form';
        this.selectedForm.data = this.formulaires[redirection.targetFormulaireId];
      } else {
        //display selected page
        this.selectedForm.type = 'page';
        this.selectedForm.data = this.pages[redirection.targetPageId];
      }
      //display symptomes linked to selected redirection
      this.changeSymptomes(redirection);
    }
  }

  goBack(): void {
    this.location.back();
  }

  refreshPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/admin/edit-form/'+this._activatedRoute.snapshot.params.id]);
  }

  onUpload() {
    this.validatedForm = false;
    this.errorForm = false;
    const fd = new FormData();

    for (let img of this.multipleSelectedFiles) {
      fd.append('pictures', img, img.name);
    }

    /*if (this.postFormulairesData.first === undefined) {
      this.postFormulairesData.first = ""+false;
    }*/
    fd.append('_id', this.postFormulairesData._id);
    fd.append('name', this.postFormulairesData.name);
    fd.append('folder', this.postFormulairesData.folder);
    fd.append('first', ''+this.postFormulairesData.first);
    fd.append('question', this.postFormulairesData.question);
    fd.append('multipleChoice', ''+this.postFormulairesData.multipleChoice);
    fd.append('templateNumber', ''+this.postFormulairesData.templateNumber);

     this._formulairesServices.postFormulaire(this.postFormulairesData._id, fd)
    .subscribe(
      res => {
        this.multipleSelectedFiles = []
        this.urlPictures = [];
        this.formPictures = [];
        for (let img of res.pictures) {
          this.formPictures.push(this.svrUrl + img);
        }
        this._router.navigate(['/admin/edit-form/' + res._id]);

        // console.log(this.newSymptome);
        if (this.newSymptome.label != "") {
          let orderN = 1;/////// add order
          if(this.symptomesDrag.length>0) {orderN = (this.symptomesDrag[this.symptomesDrag.length-1].order)  +1;}/////// add order
          this.newSymptome.order = orderN; /////// add order

          // on gère le formulaire
          const fdSymptome = new FormData();

          fdSymptome.append('_id', this.newSymptome._id);
          fdSymptome.append('label', this.newSymptome.label);
          fdSymptome.append('type', this.newSymptome.type);
          fdSymptome.append('value', this.newSymptome.value);
          fdSymptome.append('formulaireId',this.newSymptome.formulaireId);
          fdSymptome.append('order', ""+this.newSymptome.order);

          if (this.selectedFile != null) {
            fdSymptome.append('picture', this.selectedFile, this.selectedFile.name);
          }

          this._symptomesServices.postSymptome(fdSymptome)
          .subscribe(
            res => {
              // console.log(res, this.symptome);
              this.symptomes[res._id] = res;
              this.symptomesDrag.push(res); /////// add order
              this.selectedSymptomes.push({_id: res._id, label: res.label, rule: 0});
              this.selectedFilesSymptome = [];
              this.newSymptomeUrl = "";
              this.sympPictures.push(this.svrUrl + res.picture)
              this.newSymptome = {
                _id: null,
                label: "",
                type: "Read Only",
                value: "",
                formulaireId: this._activatedRoute.snapshot.params.id,
                order : 1,
                picture: ""
              }
              this.validatedForm = true;
            },
            err => {
              console.log(err);
              this.validatedForm = false;
              this.errorForm = true;
            }
          )
        } else {
          let index = 0;
          for(let symptome in this.symptomes) {
            let fd = this.symptomes[symptome] ;
            for (let el = 0; el < this.selectedSymptomes.length; el++) {
              if(this.selectedSymptomes[el]._id===symptome){
                this.selectedSymptomes[el].label = this.symptomes[symptome].label ;
              }
            }

            const fdSymptome = new FormData();
            fdSymptome.append('_id', fd._id);
            fdSymptome.append('label', fd.label);
            fdSymptome.append('type', fd.type);
            fdSymptome.append('value', fd.value);
            fdSymptome.append('formulaireId', fd.formulaireId);
            fdSymptome.append('order', "" + fd.order);
            if (this.selectedFilesSymptome[index] != null) {
              fdSymptome.append('picture', this.selectedFilesSymptome[index], this.selectedFilesSymptome[index].name);
            }
            console.log(this.selectedFilesSymptome);

            this._symptomesServices.postSymptome(fdSymptome).subscribe(
              res => {
                //console.log(res);
                this.selectedFilesSymptome = [];
                this.newSymptomeUrl = "";
              },
              err => {console.log(err);}
            );
            index++;
          }
        }
        if (this.selectedForm.data._id != null){
          if (this.selectedForm.type == 'form'){
            this.nextRedirection.targetFormulaireId = this.selectedForm.data._id;
          } else if (this.selectedForm.type == 'page') {
            this.nextRedirection.targetPageId = this.selectedForm.data._id;
          }
          for(let symptome of this.selectedSymptomes){
            if(symptome.rule == 1){this.nextRedirection.rules.push(symptome._id)}
            if(symptome.rule == 2){this.nextRedirection.rulesHard.push(symptome._id)}
            if(symptome.rule == 3){this.nextRedirection.rulesExcluded.push(symptome._id)}
          }

          let orderN = 1;/////// add order
          if(this.redirectionsDrag.length>0) {orderN = (this.redirectionsDrag[this.redirectionsDrag.length-1].order)  +1;}/////// add order
          this.nextRedirection.order = orderN; /////// add order

          this._redirectionsServices.postRedirection(this.nextRedirection).subscribe(
            res => {
              // console.log(res, this.nextRedirection);
              this.redirections[res._id] = res;
              this.redirectionsDrag.push(res); /////// add order
              this.unselectRedirection();

              this.nextRedirection = {
                _id: null,
                formulaireId: this._activatedRoute.snapshot.params.id,
                rulesExcluded: [],
                rulesHard: [],
                rules: [],
                targetFormulaireId: null,
                targetPageId: null,
                issueDe: [],
                order : 1
              };
              this.validatedForm = true;
            },
            err => {
              console.log(err);
              this.validatedForm = false;
              this.errorForm = true;
            }
          )
        }
      },
      err => console.log(err)
    )
    //alert('Modification effectuée !');
  }

  upLoadPicture () {
    // console.log(this.postFormulairesData);
    const fd = new FormData();
    for (let img of this.multipleSelectedFiles) {
      fd.append('pictures', img, img.name);
    }

    this._formulairesServices.uploadPicture(this.postFormulairesData._id, fd)
    .subscribe(
      res => {
        // console.log(res);
        // alert('Les photos ont bien été uploadés.');
        this.multipleSelectedFiles = []
        this.urlPictures = [];
        this.formPictures = [];
        for (let img of res.pictures) {
          this.formPictures.push(this.svrUrl + img);
        }
        this._router.navigate(['/admin/edit-form/' + res._id]);
      },

      err => {
        if (this.postFormulairesData.name === "" || this.postFormulairesData.folder === "" || this.postFormulairesData.question === "") {
            alert("L'un des champs suivant n'a pas été rempli :\n- Nom du formulaire\n- Dossier\n- Question");
        }
        console.log(err);
      }
    )
  }

  deleteFormulaire() {
    // console.log(this.postFormulairesData);
    if(confirm("Êtes-vous sûr.e de vouloir supprimer " + this.postFormulairesData.name + " ?")) {
      this._formulairesServices.deleteFormulaire(this.postFormulairesData._id)
      .subscribe(
        res => {
          // console.log(res);
          this._router.navigate( ['/admin/idiagnose']);
        },
        err => console.log(err)
      )
    }
  }

    onDropSymptome(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.symptomesDrag, event.previousIndex, event.currentIndex);
      this.symptomesDrag.forEach((symptomesDrag, idx) => {
        symptomesDrag.order = idx + 1;
      });
      for (let el = 0; el < this.symptomesDrag.length; el++) {
          let fd = this.symptomes[this.symptomesDrag[el]._id] ;
          const fdSymptome = new FormData();
          fdSymptome.append('_id', fd._id);
          fdSymptome.append('label', fd.label);
          fdSymptome.append('type', fd.type);
          fdSymptome.append('value', fd.value);
          fdSymptome.append('formulaireId', fd.formulaireId);
          fdSymptome.append('order', "" + fd.order);
          if (this.selectedFilesSymptome[el] != null) {
            fdSymptome.append('picture', this.selectedFilesSymptome[el], this.selectedFilesSymptome[el].name);
          }

          this._symptomesServices.postSymptome(fdSymptome).subscribe(
            res => {console.log(res);},
            err => {console.log(err);});
           }
    }
    onDropRedirection(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.redirectionsDrag, event.previousIndex, event.currentIndex);
       this.redirectionsDrag.forEach((redirectionsDrag, idx) => {
         redirectionsDrag.order = idx + 1;
       });
       for (let el = 0; el < this.redirectionsDrag.length; el++) {
         let fd = this.redirections[this.redirectionsDrag[el]._id] ;
         // console.log(fd);
         this._redirectionsServices.postRedirection(fd).subscribe(
             res => {/*console.log(res)*/},
             err => {console.log(err);}
           )
       }
    }
    editIssueDe(id,issue){
        this.issueDeSymptome = this.nextRedirection.issueDe[id].selectedSymptomes[0]._id ;
        this.issueDeCurse = issue ;
        this.issueDeMode = true ;
        this.currentIssueDe.active = true ;
        this.editIssueDeSymptomeElement = id ;
        this.issueDeFormSelected(this.issueDeSymptome);
    }
    modifIssueDe(){
        if(this.currentIssueDe.selectedSymptomes[this.issueDeSymptome] === undefined) {
            this.addIssueDe();
            this.deleteIssueDe(this.issueDeCurse);
        }
        else {
          this.nextRedirection.issueDe[this.editIssueDeSymptomeElement].selectedSymptomes[0] = this.currentIssueDe.selectedSymptomes[this.issueDeSymptome] ;
        }
        let fd = this.currentRedirection;
        this._redirectionsServices.postRedirection(fd).subscribe(
             res => {console.log(res);},
             err => {console.log(err);}
           )
        this.issueDeMode = false ;
        this.currentIssueDe = {
          active: false,
          sourceForm: "",
          symptomsForm: [],
          selectedSymptomes: []
        }
    }
    cancelIssueDe(){
      this.currentIssueDe.active = false ;
      this.issueDeMode = false ;
    }

    deletePicture(nb) {
      this._formulairesServices.deleteFormulairePicture(this.postFormulairesData._id, nb)
      .subscribe(
        res => {
          // console.log(res);
          this.formPictures.splice(nb, 1);
        },
        err => {
          console.log(err);
        }
      )
    }

    deleteNewPicture(nb) {
      // console.log(this.multipleSelectedFiles)

      this.multipleSelectedFiles = Array.from(this.multipleSelectedFiles);

      this.multipleSelectedFiles.splice(nb, 1);
      this.urlPictures.splice(nb, 1);
    }

    sympDeletePictures(symptomeId, nb) {
      this._symptomesServices.deletePictureSymptome(symptomeId)
          .subscribe(
            res => {
              this.sympPictures.splice(nb, 1);
            },
            err => {
              console.log(err);
            }
          )
    }
}
