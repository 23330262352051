<form>
    <div class="row">
        <div class="col-12">
            <h2>Ajouter un practicien</h2>
        </div>
    </div><br>
    <div class="form-row">
        <div class="form-group col-md-8">
            <div class="form-group">
                <label for="input3">Nom</label>
                <input type="text" class="form-control" [(ngModel)]="practicienData.name" name="name" id="input3" aria-describedby="article-nom" placeholder="Entrer un nom" required>
            </div>
            <div class="form-group">
                <label for="input5">Adresse</label>
                <input type="text" class="form-control" [(ngModel)]="practicienData.address" id="input5" aria-describedby="folder" name="folder" placeholder="Ex: 18 rue du Dr Maboul 75011 Paris" required>
            </div>
            <div class="form-group">
                <label for="input6">Skill</label>
                <datalist hidden [ngModelOptions]="{standalone: true}"  [(ngModel)]="practicienData.skill" id="lumidata" class="form-control" >
                    <option *ngFor="let skill of skills" [value]="skill"  >{{skill}}</option>  
                </datalist>
                <input [ngModelOptions]="{standalone: true}"  [(ngModel)]="practicienData.skill"  class="form-control"  type="text" list="lumidata" >
                

            </div>
              <div class="form-group">
                <label for="input7">Lattitude</label>
                <input type='number' class="form-control" [(ngModel)]="practicienData.lat" id="input6" aria-describedby="lattitude" name="lattitude" >
            </div>
            <div class="form-group">
                <label for="input8">Longitude</label>
                <input type='number' class="form-control" [(ngModel)]="practicienData.lng" id="input6" aria-describedby="longitude" name="longitude" >
            </div>
        </div>
        <hr />
        <div class="form-group col-md-8">
            <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
            <a routerLink="/admin/ifind" class="btn btn-light">Cancel</a>
        </div>
    </div>
</form>