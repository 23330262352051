<header>

    <div class="ic-container">
        <div class="ic-top-menu">
            <a routerLink="/formulaires" href="#" class="ic-diagnose-link" [ngClass]="{active: section == 'diagnose'}" (click)="section = 'diagnose'">I-DIAGNOSE</a>
            <a routerLink="/practiciens" href="#" class="ic-find-link" [ngClass]="{active: section == 'find'}" (click)="section = 'find'">I-FIND</a>
            <a routerLink="/" [ngClass]="{active: section == 'main'}" (click)="section = 'main'"><img src="assets/img/admin/top-menu-logo-full.png" class="ic-logo" alt="iCare"></a>
            <a routerLink="/" href="#" class="ic-prevent-link" [ngClass]="{active: section == 'prevent'}" (click)="section = 'prevent'">I-PREVENT</a>
            <a routerLink="/learn" href="#" class="ic-learn-link" [ngClass]="{active: section == 'learn'}" (click)="section = 'learn'">I-LEARN</a>
        </div>
    </div>
</header>