 <header>
  <div class="container">
 
    <br>
    <div *ngIf='nbResult' class='result'>
      <strong>{{nbResult}}</strong><span> spécialistes trouvés</span>
    </div>
    <br>
      
      <a id="anc"></a>
      <div class="col-12">
            <div class="input-group recherche">
              <div class="input-group-prepend ">
                <select id="oCategorie" name="oCategorie" class="form-control" [(ngModel)]='searchType' >
                  <option value="Tous">Tous</option>
                  <option *ngFor="let skill of skills" [value]="skill"  >{{skill}}</option>  
                </select>
              </div>
              <input id="oSaisie" name="oSaisie" type="text" class="form-control oSaisie" [(ngModel)]='searchTerm'  placeholder = 'Effectuer une recherche ...' >
              <div class="input-group-append">
                <button class="btn btn-secondary" (click)='rechercher()'>➤</button>
              </div>
            </div>
      </div>

      <br>
      <br>

      
      <div class="col-12">
        <div class="map-wrapper">
          <div class="map-border">
            <div id="map"></div>
          </div>
        </div>
      </div>
      
      <br>
      <br>
  
      <table class="col-12 table-striped table-hover practiciens">
        <thead>
          <tr>
            <th scope="col">Nom</th>
            <th scope="col">Spécialité</th>
          </tr>
        </thead>
          
              <tbody>
                  <tr *ngFor="let practicien of practiciensB ">
                      <td onclick="javascript:window.scrollTo(0,0);" (click)="showPracticien(practicien.lat,practicien.lng,practicien.name,practicien.address)"><strong>{{practicien.name}}</strong><br>{{practicien.address}}</td>
                      <td>{{practicien.skill}}</td>
                  </tr>
              </tbody>
        
      </table>
      <br>
      <br>
     
  </div>
  
 </header>