/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AlertMsgComponent } from './components/admin/alert-msg/alert-msg.component';
import { AdminHeaderComponent } from './components/admin/header/header.component';
import { AdminFooterComponent } from './components/admin/footer/footer.component';

//import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    AlertMsgComponent
    //FilterPipe
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AlertMsgComponent,
    AdminHeaderComponent,
    AdminFooterComponent
    //FilterPipe
  ]
})
export class SharedModule { }
