/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SymptomesService {

  private _url = environment.apiBaseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private router: Router) { }

  getSymptomes(formulaireId) {
    return this.http.get<any>(this._url + "/symptomes/"+formulaireId)
  }

  getSymptome(symptomeId) {
    return this.http.get<any>(this._url + "/symptomes/"+symptomeId)
  }

  postSymptome(symptome) {
    console.log(symptome.get('_id'));

    if (symptome.get('_id') !== "null") {
      // console.log('patch');
      return this.http.patch<any>(this._url + "/symptomes/"+symptome.get('_id'), symptome);
    } else {
      // console.log('post');
      return this.http.post<any>(this._url + "/symptomes", symptome);
    }
  }

  deleteSymptome(symptomeId) {
    return this.http.delete<any>(this._url + "/symptomes/"+symptomeId)
  }

  deletePictureSymptome(symptomeId) {
    return this.http.delete<any>(this._url + "/symptomes/"+symptomeId+"/picture")
  }
}
