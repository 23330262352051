/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router ) { }
  canActivate(): boolean {
    if (this.authService.loggedIn()) {
      if(this.authService.isTokenExpired(this.authService.getToken())){
        alert('Merci de vous connecter');
        this.authService.logout();
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
