/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { ArticlesService } from '../../../../services/articles/articles.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.css']
})
export class AdminAddArticleComponent implements OnInit {

  articleData = {
    _id: null,
    type: "",
    folder: "",
    name: "",
    content: "",
    picture: "",
    excerpt: "",
    featured: false,
    contentMore: "",
    pictureLegend: ""
  }

  urlPicture = null;
  selectedFile = null;

  config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '30rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        }
      ]
    };

  constructor(
    private _articlesServices: ArticlesService,
    private _router: Router,
    private location: Location
    ) { }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    console.log(this.articleData);

    this._articlesServices.postArticle(null, this.articleData)
    .subscribe(
      res => {
        console.log(res);
        this._router.navigate( ['/admin/edit-article/' + res._id]);
      },
      err => {
        console.log(err);
      }
    );
  }

  onFileSelected(event): void {
    // console.log(event);
    if (event.target.files) {
      this.selectedFile = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(event:any)=>{
        this.urlPicture = event.target.result;
      };
    }
  }

}
