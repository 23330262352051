import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor() { }

  @Output() search = new EventEmitter<string>();
  @Output() reset = new EventEmitter<any>();

  addNewSearch(value: string) {
    this.search.emit(value);
  }

  resetResearch() {
    this.reset.emit(null);
  }

  ngOnInit(): void {
  }

}
