<div id='box'>
  <div>
    <h1>Apprendre sur la sante et les maladies</h1>
  </div>
  <div class='largeur'>
    <app-search></app-search>
  </div>
  <div>
    <img src="assets/img/learn/3-bodies.png">
  </div>
  <div class='largeur' *ngFor="let section of listSectionTest">
    <app-section [title]='section.title' [listArticle]='section.listArticles'></app-section>
  </div>
</div>
