/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { PracticiensService } from '../../../../services/practiciens/practiciens.service';
import * as L from 'leaflet';
import {MarkerClusterGroup} from "leaflet.markercluster";

@Component({
  selector: 'app-admin-edit-practicien',
  templateUrl: './edit-practicien.component.html',
  styleUrls: ['./edit-practicien.component.css']
})

export class AdminEditPracticienComponent implements OnInit {


  practicienData = {
    _id: null,
    name: "",
    address: "",
    type: "",
    skill: "",
    subskill : "" ,
    lat: 0,
    lng: 0
  }
  skills = [] ;

  map ;
  mainLayer ;
  markerCluster ;
  marker ;
  maxZoom  = 20;
  currentZoom = 12 ;

  origin = {
    lat : 48.8534,
    lng : 2.3488
    };

  icons = new L.Icon({
      iconSize:     [25, 41],
      iconAnchor:   [10, 41],
      popupAnchor:  [2, -40],
      iconUrl:      "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl:    "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    });

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private location: Location,
    private _practiciensServices: PracticiensService,

  ) {
    let params: any = this._activatedRoute.snapshot.params;
    // console.log(params.id);
    this._practiciensServices.getSkill()
        .subscribe(
          res => {
            this.skills = res ;
           },
          err => console.log(err)
        );
    this._practiciensServices.getPracticien(params.id)
    .subscribe(
      res => {
        this.practicienData = res;
        this.createMap();
      },
      err => {
        console.log(err);
        this._router.navigate(['/admin/ifind']);
      }
    )
  }

  ngOnInit(): void { }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    const fd = new FormData();
    fd.append('_id', this.practicienData._id);
    fd.append('name', ''+this.practicienData.name);
    fd.append('address', ''+this.practicienData.address);
    fd.append('type', ''+this.practicienData.type);
    fd.append('lat', ''+this.practicienData.lat);
    fd.append('lng', ''+this.practicienData.lng);
    fd.append('skill', ''+this.practicienData.skill);
    fd.append('subskill', ''+this.practicienData.subskill);
    // console.log(this.practicienData._id);
    this._practiciensServices.postPracticien(this.practicienData._id, fd)
    .subscribe(
      res => {console.log(res);},
      err => {console.log(err);}
    )
  }

  deletePracticien() {
    if(confirm("Êtes-vous sûr.e de vouloir supprimer " + this.practicienData.name + " ?")) {
      this._practiciensServices.deletePracticien(this.practicienData._id)
      .subscribe(
        res => {
          this._router.navigate( ['/admin/ifind']);
        },
        err => console.log(err)
      )
    }
  }

   createMap() {
    this.mainLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
       maxZoom: this.maxZoom,
       attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
     });
     this.map = L.map('map', {
       center: [48.8534, 2.3488],
       zoom: this.currentZoom,
       zoomControl: true, layers: [this.mainLayer]
     });
     this.updatePosition();
  }

  updatePosition(){
    this.marker = L.marker([this.practicienData.lat, this.practicienData.lng], { icon: this.icons });
    this.marker.addTo(this.map).bindPopup('<strong>'+this.practicienData.name+'</strong><br>'+this.practicienData.address).openPopup();
  }

  updateMap(){
    this.map.removeLayer(this.marker);
    this.updatePosition();
  }


}
