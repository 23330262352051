<form>
    <span [ngClass]="{'alert alert-success alert-dismissible fade':true, show:validatedForm}" role="alert">
            Les modifications ont bien été prises en compte.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
    </button>
    </span>
    <span [ngClass]="{'alert alert-warning alert-dismissible fade':true, show:errorForm}" role="alert">
            Les modifications n'ont pu être enregistrées.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
    </button>
    </span>
    <br>
    <br>
    <div class="row">
        <div class="col-12">
            <h1>Modifier le formulaire {{ postFormulairesData.name }}</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    Form description
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="input1">Formulaire (Nom interne)</label>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.name" name="name" id="input1" aria-describedby="formulaire-nom" placeholder="Entrer un nom">
                    </div>
                    <div class="form-group">
                        <label for="input2">Folder</label>
                        <small id="folder" class="form-text text-muted">Ex: /Digestif/Targets digestif</small>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.folder" id="input2" aria-describedby="folder" name="folder" placeholder="Entrer un nom">
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="postFormulairesData.first" name="first" id="input4" aria-describedby="formulaire-first">
                        <label for="input4" class="form-check-label">Premier formulaire</label>
                    </div>
                    <div class="form-group">
                        <label for="input3">Question (pour l'utilisateur)</label>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.question" id="input3" aria-describedby="quetion" name="question" placeholder="Entrer une question">
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="postFormulairesData.multipleChoice" name="multipleChoice" id="input5" aria-describedby="formulaire-multipleChoice">
                        <label for="input5" class="form-check-label">Choix multiple</label>
                    </div>
                    <div class="form-group">
                        <label for="input4">Choix</label>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Choix</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody cdkDropList (cdkDropListDropped)="onDropSymptome($event)">
                                <tr *ngFor="let symptome of symptomesDrag | keyvalue; index as i;">
                                    <th>
                                        <div class="drag-handle" cdkDrag cdkDragLockAxis="y">
                                            <ng-container [ngTemplateOutlet]="dragTmpl"></ng-container>
                                        </div>
                                    </th>
                                    <td>
                                        <button (dblclick)="fileInput.click()" *ngIf="!sympPictures[i]">+</button>
                                        <img [src]=sympPictures[i] (click)="fileInput.click()" class="form-picture-very-small" *ngIf="sympPictures[i]" />
                                        <button (click)="sympDeletePictures(symptome.value._id, i)" *ngIf="sympPictures[i]">-</button>
                                        <input #fileInput type="file" (change)="onFileChangeSelected($event, i)" style="display:none;" />
                                    </td>
                                    <td>
                                        <select class="form-control" (change)="onUpload()" [(ngModel)]="symptome.value.type" name="new-symptome-type[{{i}}]" id="select-symptome-[{{i}}]">
                                          <option value="Read Only">Read Only</option>
                                          <option value="Free Text">Free Text</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" [(ngModel)]="symptome.value.label" id="input{{ i }}" name="symptome-label[{{i}}]" placeholder="Entrer un choix">
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-light btn-sm" (click)="deleteSymptome(symptome.value._id)">x</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button type="button" (click)="fileInputNewSymptom.click()" *ngIf="!newSymptomeUrl">+</button>
                                        <input #fileInputNewSymptom type="file" (change)="onFileSelected($event)" style="display:none;">
                                        <img [src]=newSymptomeUrl (click)="fileInputNewSymptom.click()" class="form-picture-very-small" *ngIf="newSymptomeUrl" /><br />
                                    </td>
                                    <td scope="row">
                                        <select class="form-control" [(ngModel)]="newSymptome.type" name="new-symptome-type" id="select1">
                                          <option value="Read Only">Read Only</option>
                                          <option value="Free Text">Free Text</option>
                                        </select>
                                    </td>
                                    <td><input type="text" class="form-control" [(ngModel)]="newSymptome.label" name="new-symptome-label" id="input4" aria-describedby="choice" placeholder="Entrer un choix"></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="text-right">
                        <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Redirection rules
                </div>
                <div class="card-body">
                    Comment les règles sont priorisées:<br /> #0: règles désignant des symptômes incompatibles. Si des symptômes incompatibles sont sélectionnés, rien ne se passe, un message d'erreur s'affiche.<br /> #1: règles ayant plusieurs symptômes
                    "hard", matchant avec la sélection (il faut que TOUS soient présent dans la sélection).<br /> #2: règles avec un symptome "hard" matchant avec la sélection.<br /> #3: règles dont les symptômes correspondent exactement à ceux sélectionnés
                    + dont "issu de" correspond au parcours utilisateur<br /> #4: règles dont les symptômes correspondent exactement à ceux sélectionnés<br /> #5: règles n'ayant aucun symptôme mais dont "issu de" correspond au parcours utilisateur<br
                    /> #6: règles n'ayant aucun symptôme<br /> #Note: Si plusieurs règles ont un niveau de priorité égal, c'est celle qui a été créé en première (la plus haute dans la liste) qui est appliquée.<br />
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    Illustrations
                </div>
                <div class="card-body">
                    <div class="card-body-image" *ngFor="let img of formPictures; let i = index">
                      <img [src]=formPictures[i] class="form-picture-small" />
                      <button class="btn btn-sm btn-danger btn-delete" (click)="deletePicture(i)">x</button>
                    </div>
                    <div class="card-body-image" *ngFor="let img of multipleSelectedFiles; let i = index">
                      <img [src]=urlPictures[i] class="form-picture-small" />
                      <button class="btn btn-sm btn-danger btn-delete" (click)="deleteNewPicture(i)">x</button>
                    </div>

                    <input type="file" multiple (change)="onMultipleSelectImages($event)"><br><br>
                    <button type="button" (click)="upLoadPicture()" class="btn btn-primary">Upload</button><br><br>
                    <label for="input6">Template d'affichage</label>
                    <select class="form-control" name="template-number" id="select2" [(ngModel)]="postFormulairesData.templateNumber">
                        <option value='1'>Template 1 (affichage formaté pour le premier formulaire)</option>
                        <option value='2'>Template 2 (affichage image(s) du formulaire + symptôme) </option>
                        <option value='3'>Template 3 (affichage image(s) du formulaire + symptômes/image des symptômes) </option>
                        <option value='4'>Template 4 (affichage symptômes/images des symptôme</option>
                        <option value='5'>Template 5 (affichage en grille / symptômes/image des symptômes)</option>
                        <option value='6'>Template 6 (template 2 avec 'suivant' activé > Other case)</option>
                    </select>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Symptômes
                </div>
                <div class="card-body">
                    <div  *ngFor="let symptome of selectedSymptomes; index as i;">
                        <button (click)="clickSymptome(i)" type="button" [ngClass]="{'btn btn-sm': true, 'btn-outline-dark': symptome.rule == 0, 'btn-secondary': symptome.rule == 1, 'btn-primary': symptome.rule == 2, 'btn-danger': symptome.rule == 3}"><img [src]=sympPictures[i] class="form-picture-very-small" *ngIf="sympPictures[i]" /> {{ symptome.label }} <span class="badge"></span></button><br>
                    </div>

                    <br>
                    <div class="float-none"></div>
                    <p> Sélectionné : {{ selectedForm.data.name }}</p>
                    <div class="form-check">
                        <input [disabled]="selectedForm.type == null" class="form-check-input" type="checkbox" [(ngModel)]="currentIssueDe.active" name="issue-de" id="check1">
                        <label class="form-check-label" for="check1">
                        Issue de...
                      </label>
                    </div>
                    <div class="form-group" *ngIf="currentIssueDe.active">
                        <label for="inputlist1">Source Form:</label>
                        <select id="inputlist1" class="form-control" (change)="issueDeFormSelected($event.target.value)">
                        <option [selected]="currentIssueDe.sourceForm==''"></option>
                        <!--<option *ngIf="currentIssueDe.sourceForm!=''" selected>{{formulaires[currentIssueDe.sourceForm].name}}</option>-->
                        <option *ngFor="let formulaire of formulaires | keyvalue" [value]="formulaire.value._id">{{formulaire.value.name}}</option>
                      </select>
                        <label for="inputlist2">Symptômes: </label>
                        <select class="form-control" *ngIf="currentIssueDe.sourceForm != null" (change)="issueDeSymptomSelected($event.target.value)" id="inputlist2">
                        <option selected></option>
                        <option  *ngFor="let symptome of currentIssueDe.symptomsForm | keyvalue" [value]="symptome.value._id">{{symptome.value.label}}</option>
                      </select>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>hard</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let symptome of currentIssueDe.selectedSymptomes | keyvalue">
                                    <td>
                                        <div class="form-check"><input class="form-check-input position-static" type="checkbox" id="check2" [(ngModel)]="symptome.value.hard" name="rule-hard"></div>
                                    </td>
                                    <td>{{symptome.value.label}}</td>
                                    <div *ngIf="symptome.value.type == 'Free Text'">
                                        <td><button type="button" class="btn btn-light btn-sm" (click)="changeCompareSymbole(symptome.value._id)">{{symptome.value.compareSymbole}}</button></td>
                                        <td class="col-xs-1"><input type="number" class="form-control" [(ngModel)]="symptome.value.compareValue" name="comparator"></td>
                                    </div>
                                    <td class="text-right"><button type="button" class="btn btn-light btn-sm" (click)="deleteIssueDeSymptome(symptome.value._id)">x</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <!--<button type="button" class="btn btn-info btn-sm" (click)="addIssueDe()">Ajouter</button>-->
                        <div *ngIf="!issueDeMode" class="text-right">
                            <button type="button" class="btn btn-info " (click)="addIssueDe()">Ajouter</button>&nbsp;
                        </div>
                        <div *ngIf="issueDeMode" class="text-right">
                            <button type="button" class="btn btn-warning " (click)="modifIssueDe()">Modifier</button>&nbsp;
                            <button type="button" class="btn btn-secondary " (click)="cancelIssueDe()">Annuler</button>&nbsp;
                        </div>
                    </div>
                    <br>
                    <table class="table table-sm">
                        <tbody>
                            <tr *ngFor="let issue of nextRedirection.issueDe; index as i">
                                <td>
                                    {{formulaires[issue.sourceForm].name}} (
                                    <span *ngFor="let symptome of issue.selectedSymptomes | keyvalue;" (click)="editIssueDe(i,issue)">{{symptome.value.label}}{{symptome.value.compareSymbole}}{{symptome.value.compareValue}}</span>)
                                </td>
                                <td class="text-right"><button type="button" class="btn btn-light btn-sm" (click)="deleteIssueDe(issue)">x</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <!--update filter symptoms williams-->
                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" (click)="sortType1 = 'name'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'name' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'name' && sortReverse1">↓</span> Formulaire</th>
                                    <th scope="col" (click)="sortType1 = 'folder'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'folder' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'folder' && sortReverse1">↓</span> Dossier</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-light">
                                    <td>
                                        <input type="text" class="form-control" name="filters" id="input5" [(ngModel)]='searchTermName1' placeholder="Name filter">
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" name="filters" id="input6" [(ngModel)]='searchTermFolder1' placeholder="Folder filter">
                                    </td>
                                </tr>
                                <!--update filter symptoms williams-->
                                <tr *ngFor="let formulaire of formulaires | keyvalue | filterEditForm1 : searchTermName1 : searchTermFolder1 : sortReverse1 : sortType1 ">
                                    <td (click)="selectForm(formulaire.value._id)">{{ formulaire.value.first ? "*": "" }} {{ formulaire.value.name }}</td>
                                    <td (click)="selectForm(formulaire.value._id)">{{ formulaire.value.folder }}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <br>
                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" (click)="sortType2 = 'name'; sortReverse2 = !sortReverse2"><span *ngIf="sortType2 == 'name' && !sortReverse2">↑</span><span *ngIf="sortType2 == 'name' && sortReverse2">↓</span> Page</th>
                                    <th scope="col" (click)="sortType2 = 'folder'; sortReverse2 = !sortReverse2"><span *ngIf="sortType2 == 'folder' && !sortReverse2">↑</span><span *ngIf="sortType2 == 'folder' && sortReverse2">↓</span> Dossier</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-light">
                                    <td>
                                        <input type="text" class="form-control" name="filters" id="input7" [(ngModel)]='searchTermName2' placeholder="Name filter">
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" name="filters" id="input8" [(ngModel)]='searchTermFolder2' placeholder="Folder filter">
                                    </td>
                                </tr>
                                <tr *ngFor="let page of pages | keyvalue | filterEditForm2 : searchTermName2 : searchTermFolder2 : sortReverse2 : sortType2">
                                    <td (click)="selectPage(page.value._id)">{{ page.value.name }}</td>
                                    <td (click)="selectPage(page.value._id)">{{ page.value.folder }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Redirections
                </div>
                <div class="card-body">
                    <table class="table">
                        <tbody cdkDropList (cdkDropListDropped)="onDropRedirection($event)">
                            <tr *ngFor="let redirection of redirectionsDrag | keyvalue" cdkDrag cdkDragLockAxis="y">
                                <th>
                                    <div class="drag-handle">
                                        <ng-container [ngTemplateOutlet]="dragTmpl"></ng-container>
                                    </div>
                                </th>
                                <td (click)="selectRedirection(redirection.value)">
                                  <strong>
                                    <div *ngIf="redirection.value.rules.length > 0"><span *ngFor="let rule of redirection.value.rules"> {{symptomes[rule].label}},</span></div>
                                    <div *ngIf="redirection.value.rulesHard.length > 0">Hard:<span *ngFor="let hard of redirection.value.rulesHard"> {{symptomes[hard].label}}, </span></div>
                                    <div *ngIf="redirection.value.rulesExcluded.length > 0">Exculded:<span *ngFor="let excluded of redirection.value.rulesExcluded"> {{symptomes[excluded].label}}, </span></div>
                                    <div *ngIf="redirection.value.rulesExcluded.length + redirection.value.rules.length + redirection.value.rulesHard.length == 0">Other cases</div>
                                  </strong>
                                    <div *ngIf="redirection.value.targetFormulaireId">{{formulaires[redirection.value.targetFormulaireId].name}}</div>
                                    <div *ngIf="redirection.value.targetPageId">{{pages[redirection.value.targetPageId].name}}</div>

                                    <div *ngFor="let issue of redirection.value.issueDe; index as i">
                                        n-{{i}}. issue de : {{formulaires[issue.sourceForm].name}} (
                                        <span *ngFor="let symptome of issue.selectedSymptomes | keyvalue;" >{{symptome.value.label}}{{symptome.value.compareSymbole}}{{symptome.value.compareValue}}</span>)
                                    </div>


                                </td>
                                <td>
                                    <a *ngIf="redirection.value.targetFormulaireId" routerLink="/admin/edit-form/{{redirection.value.targetFormulaireId}}" title="aller au formulaire" target="_blank">
                                        <ng-container [ngTemplateOutlet]="eyeTmpl"></ng-container>
                                    </a>
                                    <a *ngIf="redirection.value.targetPageId" routerLink="/admin/edit-page/{{redirection.value.targetPageId}}" title="aller à la page target" target="_blank">
                                        <ng-container [ngTemplateOutlet]="eyeTmpl"></ng-container>
                                    </a>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-light btn-sm" (click)="deleteRedirection(redirection.value)">x</button>
                                </td>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 sep">
        <hr />
        <div class="col-11">
            <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
            <a target="_blank" [routerLink]="['/formulaires', postFormulairesData._id]" class="btn btn-primary">View</a>&nbsp;
            <a routerLink="/admin/idiagnose" class="btn btn-light">Cancel</a>
        </div>
        <div class="col-1">
            <button (click)="deleteFormulaire()" class="btn btn-danger">Delete</button>
        </div>
    </div>
</form>

<ng-template #dragTmpl>
    <svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z" clip-rule="evenodd" />
  </svg>
</ng-template>
<ng-template #eyeTmpl>
    <svg version="1.1" id="Capa_1" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
     <g>
      <g>
        <path d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035 c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201 C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418 c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418 C447.361,287.923,358.746,385.406,255.997,385.406z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275 s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516 s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"/>
      </g>
    </g>
  </svg>
</ng-template>
