/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { RichTextEditorComponent, HtmlEditor, Toolbar, Image, Link, QuickToolbar } from '@syncfusion/ej2-angular-richtexteditor';

import { PagesService } from '../../../../services/pages/pages.service';
import { ArticlesService } from '../../../../services/articles/articles.service';
import { PracticiensService } from '../../../../services/practiciens/practiciens.service';
import { AlertMsgService } from '../../../../services/alert-msg/alert-msg.service';

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.css']
})
export class AdminAddPageComponent implements OnInit {

  insertImageSettings ;
  public tools: object = {
    items: ['Undo', 'Redo', '|',
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };
  pageData = {
    _id: null,
    name: "",
    presentation: "Il est possible que ces signes correspondent à",
    diagnostic: "",
    skill: "",
    folder: "",
    knowMorePageId: "",
    curesTargetPageId: "",
    explanation: ""
  }

  pages = [];
  articles = [];
  skills = [];

/*  config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '30rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        }
      ]
    };*/

  constructor(
    private _pagesServices: PagesService,
    private _articlesServices: ArticlesService,
    private _practiciensService: PracticiensService,
    private _AlertMsgService: AlertMsgService,
    private _router: Router,
    private location: Location
    ) {
      this.insertImageSettings = {
      saveFormat: "Base64",
      saveUrl: ""
      }
    }

  ngOnInit(): void {
    this._pagesServices.getPages().subscribe(
      pages => {
        // console.log(pages);
        this.pages = pages;
      }
    )

    this._articlesServices.getArticles().subscribe(
      articles => {
        // console.log(articles);
        this.articles = articles;
      }
    )

    this._practiciensService.getSkill().subscribe(
      skills => {
        // console.log(skills);
        this.skills = skills;
      }
    )
  }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    // console.log(this.pageData);

    this._pagesServices.postPage(null, this.pageData)
    .subscribe(
      res => {
        console.log(res);
        this._router.navigate( ['/admin/edit-page/' + res._id]);
      },

      err => {
        // console.log(err);
        this._AlertMsgService.addAlert({ type: "danger", message: err.error.error });
      }
    );
  }
  deletePage(){

  }
}
