/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { PracticiensService } from '../../../../services/practiciens/practiciens.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-practicien',
  templateUrl: './add-practicien.component.html',
  styleUrls: ['./add-practicien.component.css']
})
export class AdminAddPracticienComponent implements OnInit {

  practicienData = {
    _id: null,
    name: "",
    address: "",
    type: "",
    skill: "",
    subskill : "" ,
    lat: 0,
    lng: 0
  };
  skills = [] ;


  constructor(
    private _practiciensServices: PracticiensService,
    private _router: Router,
    private location: Location
    ) {
      this._practiciensServices.getSkill()
        .subscribe(
          res => {
            this.skills = res ;
           },
          err => console.log(err)
        )
    }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    console.log(this.practicienData);

    this._practiciensServices.postPracticien(null, this.practicienData)
    .subscribe(
      res => {
        console.log(res);
        this._router.navigate( ['/admin/ifind/']);
      },
      err => {
        console.log(err);
      }
    );
  }

}
