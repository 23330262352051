/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
//import { DialogModule } from '@syncfusion/ej2-angular-popups';

import { AdminBaseComponent } from './base.component';
import { SharedModule } from '../../shared/shared.module';
import { AdminListFormComponent } from '../../admin/i-diagnose/list-form/list-form.component';
import { AdminAddFormComponent } from '../../admin/i-diagnose/add-form/add-form.component';
import { AdminEditFormComponent } from '../../admin/i-diagnose/edit-form/edit-form.component';
import { AdminAddPageComponent } from '../../admin/i-diagnose/add-page/add-page.component';
import { AdminEditPageComponent } from '../../admin/i-diagnose/edit-page/edit-page.component';
import { AdminListArticleComponent } from '../../admin/i-learn/list-article/list-article.component';
import { AdminAddArticleComponent } from '../../admin/i-learn/add-article/add-article.component';
import { AdminEditArticleComponent } from '../../admin/i-learn/edit-article/edit-article.component';//
import { AdminListPracticienComponent } from '../../admin/i-find/list-practicien/list-practicien.component';
import { AdminAddPracticienComponent } from '../../admin/i-find/add-practicien/add-practicien.component';
import { AdminEditPracticienComponent } from '../../admin/i-find/edit-practicien/edit-practicien.component';


import { FilterPipeEditForm1 } from '../../shared/pipe/filter/filterEditForm1.pipe';
import { FilterPipeEditForm2 } from '../../shared/pipe/filter/filterEditForm2.pipe';
import { FilterPipeListForm1 } from '../../shared/pipe/filter/filterListForm1.pipe';
import { FilterPipeListForm2 } from '../../shared/pipe/filter/filterListForm2.pipe';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptorService} from '../../../services/token-interceptor/token-interceptor.service'

import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AdminBaseComponent,
    AdminListFormComponent,
    AdminAddFormComponent,
    AdminEditFormComponent,
    AdminAddPageComponent,
    AdminEditPageComponent,
    AdminListArticleComponent,
    AdminAddArticleComponent,
    AdminEditArticleComponent,
    AdminListPracticienComponent,
    AdminAddPracticienComponent,
    AdminEditPracticienComponent,
    FilterPipeEditForm1,
    FilterPipeEditForm2,
    FilterPipeListForm1,
    FilterPipeListForm2
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AngularEditorModule,
    DragDropModule,
    RichTextEditorAllModule,
    //DialogModule
  ],
  providers:[{
    provide : HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi : true
  }],
  exports: [
    FilterPipeEditForm1,
    FilterPipeEditForm2,
    FilterPipeListForm1,
    FilterPipeListForm2
  ]
})
export class AdminBaseModule { }
