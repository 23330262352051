/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateArticle } from './template.interface';
import {listArticle} from './articleContent'

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  image: string;
  title: string;
  synopsis: string;
  content: string;

  allArticle : TemplateArticle[] = listArticle;

  constructor(private route: ActivatedRoute) {

  }
  ngOnInit(): void {

    const id = +this.route.snapshot.paramMap.get('articleId');

    const articleFound : TemplateArticle = this.allArticle.find(article => article.id === id);

    this.image = articleFound.image;
    this.title = articleFound.title;
    this.synopsis = articleFound.synopsis;
    this.content = articleFound.content;

  }
}
