<form class="form-register">
    <p class="text-center">
        <img src="assets/img/admin/logo.png" class="ic-logo" alt="iCare">
    </p>

    <h1>Créez votre compte</h1>

    <div class="form-group">
        <label for="inputEmail">Addresse mail</label>
        <input type="text" [(ngModel)]="registerUserData.email" id="inputEmail" name="email" class="form-control" placeholder="Entrez votre addresse mail" required autofocus>
    </div>

    <div class="form-group">
        <label for="inputEmail2">Confirmez l'addresse mail</label>
        <input type="text" [(ngModel)]="registerUserData.email2" id="inputEmail2" name="email2" class="form-control" placeholder="Confirmez votre addresse mail" required>
    </div>

    <div class="form-group">
        <label for="inputPassword">Mot de passe</label>
        <input type="password" [(ngModel)]="registerUserData.password" id="inputPassword" name="password" class="form-control" placeholder="Entrez votre mot de passe" required>
    </div>

    <div class="form-group">
        <label for="inputPassword2">Confirmez votre mot de passe</label>
        <input type="password" [(ngModel)]="registerUserData.password2" id="inputPassword2" name="password2" class="form-control" placeholder="Confirmez votre mot de passe" required>
    </div>

    <div class="form-group">
        <p class="text-center">
            <a routerLink="/login">Vous avez déjà un compte ?</a>
        </p>
    </div>

    <button (click)="registerUser()" class="btn btn-lg btn-primary btn-block" type="submit">Créez votre compte</button>
</form>