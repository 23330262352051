<section class="container ic-learn">
    <div class="col-8 offset-md-2">
      <div class="row article-intro">
        <div class="col-4">
            <img [src]=url+article.picture alt="" />
        </div>
        <div class="col-8 text-center">
            <h1>{{ article.name }}</h1>
            <p>{{ article.excerpt }}</p>
        </div>
      </div>
      <div class="row">
        <p class="article-content" [innerHTML]=article.content></p>
        <p class="article-content" [innerHTML]="article.contentMore"></p>
      </div>
    </div>
</section>
