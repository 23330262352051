<form>
    <div class="row">
        <div class="col-12">
            <h2>Ajouter une page Target {{ pageData.name }}</h2>
        </div>
    </div><br>

    <div class="form-group">
        <label for="input1">Titre (Nom interne)</label>
        <input type="text" class="form-control" [(ngModel)]="pageData.name" name="name" id="input1" aria-describedby="page-target-nom" placeholder="Entrer un nom" required>
    </div>
    <div class="form-row">
        <div class="form-group col-6">
            <label for="input2">Presentation</label>
            <input type="text" class="form-control" [(ngModel)]="pageData.presentation" id="input2" aria-describedby="presentation" name="presentation" placeholder="Entrer un texte">
        </div>
        <div class="form-group col-6">
            <label for="input3">Diagnostic</label>
            <input type="text" class="form-control" [(ngModel)]="pageData.diagnostic" id="input3" aria-describedby="diagnostic" name="diagnostic">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-6">
            <label for="input4">Skill</label>
            <select class="form-control" [(ngModel)]="pageData.skill" id="input6" aria-describedby="skill" name="skill">
              <option selected></option>
              <option  *ngFor="let skill of skills" [value]="skill">{{skill}}</option>
            </select>
        </div>
        <div class="form-group col-6">
            <label for="input5">Folder</label>
            <input type="text" class="form-control" [(ngModel)]="pageData.folder" id="input5" aria-describedby="folder" name="folder" placeholder="Ex: /Digestif/Targets digestif">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-6">
            <label for="input6">Know more Target</label>
            <select class="form-control" [(ngModel)]="pageData.knowMorePageId" id="input6" aria-describedby="know-more" name="knowmorepage">
              <option selected></option>
              <option  *ngFor="let article of articles" [value]="article._id">{{article.name}}</option>
            </select>
        </div>
        <div class="form-group col-6">
            <label for="input7">Cures target</label>
            <select class="form-control" [(ngModel)]="pageData.curesTargetPageId" id="input7" aria-describedby="cures-target" name="curestarget">
              <option selected></option>
              <option  *ngFor="let article of articles" [value]="article._id">{{article.name}}</option>
            </select>
        </div>
    </div>
    <div class="form-row">
        <label for="input8">Explanation</label>
        <!--<angular-editor [(ngModel)]="pageData.explanation" [ngModelOptions]="{standalone: true}" [config]="config"></angular-editor>-->
        <ejs-richtexteditor  id='iframeRTE'  [toolbarSettings]='tools'  [(ngModel)]="pageData.explanation" [ngModelOptions]="{standalone: true}"  [insertImageSettings]='insertImageSettings' ></ejs-richtexteditor>
    </div>

    <div class="row mt-4 sep">
        <hr />
        <div class="col-12">
            <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
            <a target="_blank" [routerLink]="['/pages', pageData._id]" class="btn btn-primary">View</a>&nbsp;
            <a routerLink="/admin/idiagnose" class="btn btn-light">Cancel</a>
        </div>
    </div>
</form>