<div>
    <br>
   <h3 class='ic-primary'>QUE VOULEZ VOUS FAIRE ?</h3>
   <div class="ic-section-blocs">

		<a routerLink="/formulaires" href="/formulaires"  class="ic-section-bloc ic-diagnose-section">
			<img src="assets/img/main/i-diagnose.png">

			<h3 class="ic-section-title">I DIAGNOSE</h3>

			<div class="ic-tooltip">
				Évaluer mes symptômes
			</div>
		</a>
    <a href="/practiciens" class="ic-section-bloc  ic-detect-section">
     <img src="assets/img/main/i-detect.png">
			<h3 class="ic-section-title">I FIND</h3>
			<div class="ic-tooltip">
				Trouver des médecins ou centres de santé adaptés autour de moi
			</div>
		</a>

		<a href="/main" class="ic-section-bloc  ic-prevent-section">
			<img src="assets/img/main/i-prevent.png">

			<h3 class="ic-section-title">I PREVENT</h3>

			<div class="ic-tooltip">
				Connaître mes facteurs de risque et planifier mon suivi
			</div>
		</a>

		<a routerLink="/learn" href="/learn" class="ic-section-bloc  ic-learn-section">
    <img src="assets/img/main/i-learn.png">

			<h3 class="ic-section-title">I LEARN</h3>

			<div class="ic-tooltip">
				Apprendre sur le corps humain, les maladies et leurs traitements
			</div>
		</a>

		
  <br>
	<br>
	<br>
   
	</div>
