import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-article-synopsis',
  templateUrl: './article-synopsis.component.html',
  styleUrls: ['./article-synopsis.component.css']
})
export class ArticleSynopsisComponent implements OnInit {

  constructor() { }


  @Input() id: string;
  @Input() title: string;
  @Input() image: string;
  @Input() synopsis: string;

  ngOnInit(): void {
  }

}
