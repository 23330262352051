/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticlesService } from '../../../../services/articles/articles.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-admin-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.css']
})

export class AdminEditArticleComponent implements OnInit {

  validatedForm = false;
  errorForm = false;

  articleData = {
    _id: null,
    type: "",
    folder: "",
    name: "",
    content: "",
    picture: "",
    excerpt: "",
    featured: false,
    contentMore: "",
    pictureLegend: ""
  }

  config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '30rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        }
      ]
    };

  urlPicture = null;
  selectedFile = null;
  svrUrl = environment.apiBaseUrl;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private location: Location,
    private _articlesServices: ArticlesService,

  ) {
    let params: any = this._activatedRoute.snapshot.params;
    console.log(params.id);
    this._articlesServices.getArticle(params.id)
    .subscribe(
      res => {
        this.articleData = res;
        if (res.picture != '') {
          this.urlPicture = this.svrUrl + res.picture;
        }
      },
      err => {
        console.log(err);
        this._router.navigate(['/admin/ilearn']);
      }
    )
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    this.validatedForm = false;
    this.errorForm = false;
    console.log(this.articleData);

    const fd = new FormData();
    fd.append('_id', this.articleData._id);
    fd.append('name', this.articleData.name);
    fd.append('folder', this.articleData.folder);
    fd.append('type', ''+this.articleData.type);
    if (this.selectedFile != null) {
      fd.append('picture', this.selectedFile, this.selectedFile.name);
    }
    fd.append('content', this.articleData.content);
    fd.append('excerpt', ''+this.articleData.excerpt);
    fd.append('featured', ''+this.articleData.featured);
    fd.append('contentMore', ''+this.articleData.contentMore);
    fd.append('pictureLegend', ''+this.articleData.pictureLegend);

    this._articlesServices.postArticle(this.articleData._id, fd)
    .subscribe(
      res => {
        this.validatedForm = true;
      },
      err => {
        this.errorForm = true;
      }
    )
  }

  onFileSelected(event): void {
    // console.log(event);
    if (event.target.files) {
      this.selectedFile = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(event:any)=>{
        this.urlPicture = event.target.result;
      };
    }
  }

  deleteArticle() {
    if(confirm("Êtes-vous sûr.e de vouloir supprimer " + this.articleData.name + " ?")) {
      this._articlesServices.deleteArticle(this.articleData._id)
      .subscribe(
        res => {
          this._router.navigate( ['/admin/ilearn']);
        },
        err => console.log(err)
      )
    }
  }

}
