/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterListForm1'
})
export class FilterPipeListForm1 implements PipeTransform {

  transform(formulaires: any[], searchTermName1: string, searchTermFolder1: string, sortReverse1 : boolean, sortType1 : any): any[] {
    //console.log('Call FilterPipeListForm1');
      let result = formulaires;
      if (formulaires && formulaires.length){
            result = formulaires.filter(item =>{
                if (searchTermName1 && item.name.toLowerCase().indexOf(searchTermName1.toLowerCase()) === -1) return false;
                if (searchTermFolder1 && item.folder.toLowerCase().indexOf(searchTermFolder1.toLowerCase()) === -1) return false;
                return true;
           })
        let colTri = [];
        let output = [];
        for(let i in result){
            if(sortType1=='name')colTri.push(result[i].name)
            if(sortType1=='folder')colTri.push(result[i].folder)
          }
        colTri = colTri.sort();
        if(!sortReverse1) colTri = colTri.reverse();
        let id = [];
        for(let element1 in colTri){
          for(let element2 in result){
            if(sortType1=='name') if(colTri[element1] == result[element2].name) if(id.indexOf(element2)==-1){id.push(element2); output.push(result[element2]);break}
            if(sortType1=='folder') if(colTri[element1] == result[element2].folder)  if(id.indexOf(element2)==-1) {id.push(element2); output.push(result[element2]);break}
          }
        }
        return output;
      }
    return result ;
    }
}
