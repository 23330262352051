<form>
    <div class="row">
        <div class="col-12">
            <h2>Ajouter un article</h2>
        </div>
    </div><br>

    <div class="form-row">
        <div class="form-group col-md-4">
            <label for="input1">Photo</label>
            <img [src]=urlPicture class="form-picture-small" *ngIf="urlPicture" />
            <input type="file" (change)="onFileSelected($event)"><br><br>
            <input type="text" class="form-control" [(ngModel)]="articleData.pictureLegend" name="legend" id="input1" placeholder="Entrer la légende">
        </div>
        <div class="form-group col-md-8">
            <div class="form-group">
                <label for="input3">Titre (Nom interne)</label>
                <button type="button" [ngClass]="{'btn btn-sm float-sm-right':true, 'btn-outline-secondary':!articleData.featured, 'btn-success':articleData.featured}" (click)="articleData.featured=!articleData.featured">{{articleData.featured ? 'Featured':'Not featured'}}</button>
                <input type="text" class="form-control" [(ngModel)]="articleData.name" name="name" id="input3" aria-describedby="article-nom" placeholder="Entrer un nom" required>
            </div>
            <div class="form-group">
                <label for="input5">Folder</label>
                <input type="text" class="form-control" [(ngModel)]="articleData.folder" id="input5" aria-describedby="folder" name="folder" placeholder="Ex: /Digestif/Targets digestif">
            </div>
            <div class="form-group">
                <label for="input6">Excerpt</label>
                <textarea class="form-control" [(ngModel)]="articleData.excerpt" id="input6" aria-describedby="excerpt" name="excerpt" placeholder="Entrer du texte"></textarea>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="input7">Content</label>
        <angular-editor [(ngModel)]="articleData.content" [ngModelOptions]="{standalone: true}" [config]="config"></angular-editor>
    </div>
    <div class="form-group">
        <label for="input8">Additional content</label>
        <angular-editor [(ngModel)]="articleData.contentMore" [ngModelOptions]="{standalone: true}" [config]="config"></angular-editor>
    </div>
    <div class="row mt-4 sep">
        <hr />
        <div class="col-11">
            <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
            <a routerLink="/admin/ilearn" class="btn btn-light">Cancel</a>
        </div>
    </div>
</form>