/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Injectable } from '@angular/core';
import { AlertMessage } from '../../models/AlertMessage';

@Injectable({
  providedIn: 'root'
})
export class AlertMsgService {

  alerts: AlertMessage[] = [];

  constructor() { }

  getAlerts(): AlertMessage[] {
    return this.alerts;
  }

  addAlert(alert: AlertMessage): void {
    this.alerts.push(alert);
  }
}
