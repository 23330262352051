/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterPipeListForm1} from '../../../shared/pipe/filter/filterListForm1.pipe';
import { FilterPipeListForm2} from '../../../shared/pipe/filter/filterListForm2.pipe';
import { ArticlesService } from '../../../../services/articles/articles.service';

@Component({
  selector: 'app-list-article',
  templateUrl: './list-article.component.html',
  styleUrls: ['./list-article.component.css'],
  providers: [FilterPipeListForm1,FilterPipeListForm2]
})
export class AdminListArticleComponent implements OnInit {

  articles = []

  searchTermName1: string ;
  searchTermFolder1: string ;
  sortReverse1 = false ;
  sortType1 = 'name' ;
  searchTermName2: string ;
  searchTermFolder2: string ;
  sortReverse2 = false ;
  sortType2 = 'name' ;

  constructor(
    private _articlesServices: ArticlesService,
    private _router: Router
  ) {
    this._articlesServices.getArticles()
    .subscribe(
      res => {
        this.articles = res;
        console.log(res);
      },
      err => console.log(err)
    )
  }

  ngOnInit(): void {
  }

  editArticle(article){
    console.log(article);
    this._router.navigate( ['/admin/edit-article/'+article._id]);
  }
}
