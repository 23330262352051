/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { AlertMessage } from '../../../../../models/AlertMessage';
import { AlertMsgService } from '../../../../../services/alert-msg/alert-msg.service';

@Component({
  selector: 'app-alert-msg',
  templateUrl: './alert-msg.component.html',
  styleUrls: ['./alert-msg.component.css']
})
export class AlertMsgComponent implements OnInit {

  alerts: AlertMessage[];

  constructor(
    private _alertMsgService: AlertMsgService
  ) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts(): void {
    this.alerts = this._alertMsgService.getAlerts();
  }

}
