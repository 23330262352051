<section class="container ic-learn">
    <div class="col-8 offset-md-2">
        <div class="row">
            <div class="col-12 text-center">
                <h1>Apprendre sur la sante et les maladies</h1>
            </div>
        </div>

        <div class="row justify-content-md-center">
            <div class="ic-article-search">
                <div class="col-md-6 offset-md-3">
                    <input type="text" class="form-control" name="search" id="input1" [(ngModel)]='searchTerm' placeholder="Rechercher">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/img/learn/3-bodies.png" alt="" />
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <div class="accordion">
                    <div class="card mb-3" *ngFor="let folder of folders">
                        <div class="card-header" id="headingOne">
                            <!-- <h2 class="mb-0"> -->
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            {{ folder }}
                          </button>
                            <!-- </h2> -->
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#headingOne">
                            <div class="card-body">
                                <div *ngFor="let article of articles">
                                    <div class="row article-preview" *ngIf="article.folder == folder">
                                        <div class="col-4">
                                            <img [src]='url+"/"+article.picture' alt="" />
                                        </div>
                                        <div class="col-8">
                                            <a routerLink='/i-learn/{{ article._id }}'>{{ article.name }}</a>
                                            <p>{{ article.excerpt }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>