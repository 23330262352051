/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseModule } from './components/layouts/base/base.module';
import { AdminBaseModule } from './components/layouts/admin/base.module';
import { SharedModule } from './components/shared/shared.module';

import { AuthService } from './services/auth/auth.service';
import { TokenInterceptorService } from './services/token-interceptor/token-interceptor.service';
import { AuthGuard } from './guard/auth.guard';
import { FormulairesService } from './services/formulaires/formulaires.service';
import { SymptomesService } from './services/symptomes/symptomes.service';
import { PagesService } from './services/pages/pages.service';
import { ArticlesService } from './services/articles/articles.service';
import { PracticiensService } from './services/practiciens/practiciens.service';
import { LearnComponent } from './components/front/learn/learn.component';
import { SearchComponent } from './components/front/learn/search/search.component';
import { SectionComponent } from './components/front/learn/section/section.component';
import { ArticleSynopsisComponent } from './components/front/learn/section/article-synopsis/article-synopsis.component';
import { TemplateComponent } from './components/front/template/template.component';
import { AlertMsgService } from './services/alert-msg/alert-msg.service';

@NgModule({
  declarations: [
    AppComponent,
    LearnComponent,
    SearchComponent,
    SectionComponent,
    ArticleSynopsisComponent,
    TemplateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BaseModule,
    AdminBaseModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    AuthService, AuthGuard, FormulairesService, SymptomesService, PagesService, ArticlesService, PracticiensService, AlertMsgService, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
