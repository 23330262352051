<form>
    <div class="row">
        <div class="col-12">
            <h1>Ajouter un nouveau formulaire</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    Form description
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="input1">Formulaire (Nom interne)</label>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.name" name="name" id="input1" aria-describedby="formulaire-nom" placeholder="Entrer un nom" required>
                    </div>
                    <div class="form-group">
                        <label for="input2">Folder</label>
                        <small id="folder" class="form-text text-muted">Ex: /Digestif/Targets digestif</small>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.folder" id="input2" aria-describedby="folder" name="folder" placeholder="Entrer un nom">
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="postFormulairesData.first" name="first" id="input4" aria-describedby="formulaire-first">
                        <label for="input4" class="form-check-label">Premier formulaire</label>
                    </div>
                    <div class="form-group">
                        <label for="input3">Question (pour l'utilisateur)</label>
                        <input type="text" class="form-control" [(ngModel)]="postFormulairesData.question" id="input3" aria-describedby="quetion" name="question" placeholder="Entrer une question">
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="postFormulairesData.multipleChoice" name="multipleChoice" id="input5" aria-describedby="formulaire-multipleChoice">
                        <label for="input5" class="form-check-label">Choix multiple</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    Illustrations
                </div>
                <div class="card-body">
                    <img [src]=urlPictures[i] class="form-picture-small" *ngFor="let img of multipleSelectedFiles; let i = index" />
                    <input type="file" multiple (change)="onMultipleSelectImages($event)"><br><br>
                    <label for="input6">Template d'affichage</label>
                    <select class="form-control" name="template-number" id="select2" [(ngModel)]="postFormulairesData.templateNumber">
                      <option value='1'>Template 1 (affichage formaté pour le premier formulaire)</option>
                      <option value='2'>Template 2 (affichage image(s) du formulaire + symptôme) </option>
                      <option value='3'>Template 3 (affichage image(s) du formulaire + symptômes/image des symptômes) </option>
                      <option value='4'>Template 4 (affichage symptômes/images des symptôme</option>
                      <option value='5'>Template 5 (affichage en grille / symptômes/image des symptômes)</option>
                      <option value='6'>Template 6 (template 2 avec 'suivant' activé > Other case)</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 sep">
        <hr />
        <div class="col-12">
            <button type="submit" (click)="onUpload()" class="btn btn-primary">Save</button>&nbsp;
            <a routerLink="/admin/idiagnose" class="btn btn-light">Cancel</a>
        </div>
    </div>
</form>