/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams Brett <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ArticlesService } from '../../../../services/articles/articles.service';

@Component({
  selector: 'app-ilearn-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class IlearnShowComponent implements OnInit {

  section = 'dilearnagnose';
  article = {
    _id: null,
    type: "",
    folder: "",
    name: "",
    content: "",
    picture: "",
    excerpt: "",
    featured: false,
    contentMore: "",
    pictureLegend: ""
  };
  url = "https://srv3.overconsulting.net:3000/";

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _articlesServices: ArticlesService,
  ) { }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.articleId === undefined) {
      this._router.navigate( ['/i-learn']);
    }

    this._articlesServices.getArticle(this._activatedRoute.snapshot.params.articleId)
    .subscribe(
      article => {
        this.article = article;
      }
    )
  }

}
