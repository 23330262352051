<div class="row">
  <h2>iDiagnose</h2>
</div>
<br>
<div class="row">
    <div class="col-6">
        <h4>Formulaires <a routerLink="/admin/add-form" class="btn btn-info btn-sm">+</a></h4>
        <br>
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" (click)="sortType1 = 'name'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'name' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'name' && sortReverse1">↓</span> Nom</th>
                        <th scope="col" (click)="sortType1 = 'folder'; sortReverse1 = !sortReverse1"><span *ngIf="sortType1 == 'folder' && !sortReverse1">↑</span><span *ngIf="sortType1 == 'folder' && sortReverse1">↓</span> Dossier</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input1" [(ngModel)]='searchTermName1' placeholder="Name filter">
                        </td>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input2" [(ngModel)]='searchTermFolder1' placeholder="Folder filter">
                        </td>
                    </tr>

                    <tr *ngFor="let formulaire of formulaires | filterListForm1 : searchTermName1 : searchTermFolder1 : sortReverse1 : sortType1 ">
                        <td (click)="editForm(formulaire)">{{ formulaire.first ? "*": "" }} {{formulaire.name}}</td>
                        <td>{{formulaire.folder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-6">
        <h4>Pages <a routerLink="/admin/add-page" class="btn btn-info btn-sm">+</a></h4>
        <br>
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" (click)="sortType2 = 'name'; sortReverse2 = !sortReverse2"><span *ngIf="sortType2 == 'name' && !sortReverse2">↑</span><span *ngIf="sortType2 == 'name' && sortReverse2">↓</span> Nom</th>
                        <th scope="col" (click)="sortType2 = 'folder'; sortReverse2 = !sortReverse2"><span *ngIf="sortType2 == 'folder' && !sortReverse2">↑</span><span *ngIf="sortType2 == 'folder' && sortReverse2">↓</span> Dossier</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input3" [(ngModel)]='searchTermName2' placeholder="Name filter">
                        </td>
                        <td>
                            <input type="text" class="form-control" name="filters" id="input4" [(ngModel)]='searchTermFolder2' placeholder="Folder filter">
                        </td>
                    </tr>
                    <tr *ngFor="let page of pages | filterListForm2 : searchTermName2 : searchTermFolder2 : sortReverse2 : sortType2 ">
                        <td (click)="editPage(page)">{{page.name}}</td>
                        <td>{{page.folder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
