<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 pt-4">
                <img src="assets/img/admin/logo.png" class="ic-logo" alt="iCare">
                <p class="p-4 text-muted text-center">&copy; 2020 - I Care</p>
                <a *ngIf="redirected==false"routerLink="/admin" class="ic-admin-link" href="/admin">π</a>
                <a *ngIf="redirected==true"routerLink="/admin" class="ic-admin-link" href="/admin">π</a>
            </div>
        </div>
    </div>
</footer>