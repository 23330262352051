import { ArticleSynopsis } from './section/article-synopsis/article-synopsis-interface'
import { Section } from './section/section-interface';

const articleTest: ArticleSynopsis = {
    id: 1,
    title: "test",
    image: "assets/img/learn/liver.png",
    synopsis: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
}

const ListArticleTest: ArticleSynopsis[] = [
    articleTest,
    articleTest,
    articleTest
]

const sectionTest: Section = {
    title: "test",
    listArticles: ListArticleTest
}

const lesHepatites: ArticleSynopsis = {
    id: 2,
    title: "les hépatites",
    image: "assets/img/learn/liver.png",
    synopsis: "Les hépatites constituent un groupe extrêmement large de maladies du foie, aiguës ou chroniques. Elles se définissent par la destruction des cellules du foie (appelées 'hépatocytes') et la libération dans le sang des protéines (ou enzymes) contenues dans ces hépatocytes."
}

const intolerance: ArticleSynopsis = {
    id: 3,
    title: "INTOLÉRENCE AU GLUTEN ou MALADIE COELIAQUE",
    image: "assets/img/learn/colon.jpg",
    synopsis: "Le gluten est une protéine contenue dans certaines céréales, à savoir le blé, l’orge et le seigle. La maladie cœliaque se caractérise par une réaction anormale du système immunitaire à la présence de gluten dans l’intestin grêle."
}

const tumeursDuPancréas: ArticleSynopsis = {
    id: 4,
    title: "TUMEURS DU PANCRÉAS",
    image: "assets/img/learn/pancreaticductillustratio.jpg",
    synopsis: "Le terme de “tumeur du pancréas” est un terme générique qui regroupe les tumeurs bénignes et les tumeurs malignes (ou cancers) du pancréas. Le pancréas est une petite glande située sous le foie, abouchée à l'intestin grêle, responsable en particulier de la régulation de l'assimilation des sucres par sa sécrétion d'insuline et de glucagon (voir la page diabète)."
}

const ListArticleDigestif: ArticleSynopsis[] = [
    lesHepatites,
    intolerance,
    tumeursDuPancréas
]

const digestif: Section = {
    title: "DIGESTIF",
    listArticles: ListArticleDigestif
}

const traiterUneAlgie: ArticleSynopsis = {
    id: 5,
    title: "Traiter une algie vasculaire de la face",
    image: "assets/img/learn/Arashiyama4.jpg",
    synopsis: "Le traitement comprend le traitement de la crise (dit 'symptomatique', qui vise à soulager la douleur) et le 'traitement de fond' (qui vise à diminuer la fréquence des crises)"
}

const rinçageOculaire: ArticleSynopsis = {
    id: 6,
    title: "Rinçage oculaire",
    image: "assets/img/learn/Eye_saline_solutionjpg",
    synopsis: "En cas de projection dans les yeux de produits liquides ou gazeux, de corps étranger(s) ou simplement de conjonctivite avec sécrétions plus ou moins sales, vous devez être capable de bien vous rincer les yeux. En voici la technique. Dans tous les cas, évitez de vous frotter les yeux."
}

const TraiterUnChalazion: ArticleSynopsis = {
    id: 7,
    title: "Traiter un chalazion",
    image: "assets/img/learn/liver.png",
    synopsis: "Le traitement repose sur deux piliers : les massages quotidiens des paupières pour vidanger les glandes sécrétant le meibum (corps gras) et l'application d'une pommade corticoïde sous la paupière lorsque le chalazion est inflammatoire. Malgré les traitements, les chalazions sont hélas souvent récidivants."
}

const ListArticleTraitement: ArticleSynopsis[] = [
    traiterUneAlgie,
    rinçageOculaire,
    TraiterUnChalazion
]
const traitement: Section = {
    title: "COMMENCER A TRAITER",
    listArticles: ListArticleTraitement
}

const cataracte: ArticleSynopsis = {
    id: 8,
    title: "CATARACTE",
    image: "assets/img/learn/Cataracte.png",
    synopsis: "Le cristallin est une lentille intra-oculaire transparente située derrière l’iris, qui peut être assimilée à l’objectif d’un appareil photographique. La cataracte désigne l’opacification partielle ou totale du cristallin, le plus souvent liée à une altération de la vision."
}

const soleil: ArticleSynopsis = {
    id: 9,
    title: "LE SOLEIL ET SES RISQUES SUR L'OEIL",
    image: "assets/img/learn/The_virgin_suicides_eye_cropped.jpg",
    synopsis: "Il est désormais admis que la plupart des cancers de la peau sont causés par un excès d'exposition au rayonnement solaire ultraviolet (mélanomes, carcinomes cutanés). Les risques du soleil sur les yeux sont moins connus et posent les questions suivantes : quelles parties de l'oeil peuvent être endommagées? Comment protéger ses yeux efficacement des UV? Quels sont les différences de filtration entre les différentes lunettes de soleil?"
}

const myopy: ArticleSynopsis = {
    id: 10,
    title: "myopy",
    image: "assets/img/learn/woodyallenmanhattan2.jpg",
    synopsis: "La myopie est une anomalie de la vision due à un oeil trop long, responsable d'une vision floue de loin sans lunettes. L'oeil myope est dit trop convergent ou trop puissant. Cet excès de puissance optique fait focaliser l’image d’un objet éloigné en avant de la rétine."
}

const algie: ArticleSynopsis = {
    id: 11,
    title: "ALGIE VASCULAIRE DE LA FACE",
    image: "assets/img/learn/Algie_vasculaire_de_la_face001.jpeg",
    synopsis: "L'algie vasculaire de la face est une catégorie de maux de tête, avec des salves (ou crises) de douleurs intenses et brèves (1/4 d'heure à 3 heures) séparées par des intervalles libres sans douleur (de quelques mois à quelques années). La douleur, toujours du même côté, se situe surtout autour de l'œil et s'accompagne volontiers de sueurs, larmes, écoulement du nez ou gonflement des paupières."
}

const conjonctivite: ArticleSynopsis = {
    id: 12,
    title: "conjonctivite",
    image: "assets/img/learn/Illustrations29.jpg",
    synopsis: "Une conjonctivite est une maladie très courante et bénigne de la surface de l'œil. Aussi appelée 'pink eye', elle désigne l'inflammation de la conjonctive (la fine membrane recouvrant le blanc de l'œil et sous les paupières. Le plus souvent, elle est due à une infection (virale ou bactérienne), ou à une allergie."
}

const ListArticleOphtalmologie: ArticleSynopsis[] = [
    cataracte,
    soleil,
    myopy,
    algie,
    conjonctivite
]

const ophtalmologie: Section = {
    title: "OPHTALMOLOGIE",
    listArticles: ListArticleOphtalmologie
}

const convid19: ArticleSynopsis = {
    id: 13,
    title: "COVID-19",
    image: "assets/img/learn/Fievre_toux_DRA001.jpeg",
    synopsis: "Le Covid-19 est le nom de la maladie respiratoire due au Coronavirus Sars-CoV-2, responsable de la pandémie actuelle. Sans signe de gravité, reposez-vous à domicile, buvez de l'eau, et attendez que les symptômes passent (fièvre, toux, mal de tête, courbatures) tout en respectant les mesures de confinement, en particulier si vous êtes fragiles (âgés, obèses, hypertendus, fumeurs ou avec maladies chroniques associées)."
}

const ListArticleCovid: ArticleSynopsis[] = [
    convid19,
]

const covid: Section = {
    title: "Covid",
    listArticles: ListArticleCovid
}

export const DataSection: Section[] = [
    digestif,
    traitement,
    ophtalmologie,
    covid
]