/*
 *
 * @author Loïc DANDOY <ldandoy@overconsulting.net>
 * @author Nina VARCHAVSKY <nina.varchavsky@cri-paris.org>
 * @author Williams BRETT <wllmsbrtt@gmail.com>
 * @since 01/05/2020
 */

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { RichTextEditorComponent, HtmlEditor, Toolbar, Image, Link, QuickToolbar } from '@syncfusion/ej2-angular-richtexteditor';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

import { PagesService } from '../../../../services/pages/pages.service';
import { ArticlesService } from '../../../../services/articles/articles.service';
import { PracticiensService } from '../../../../services/practiciens/practiciens.service';

@Component({
  selector: 'app-admin-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.css']
})

export class AdminEditPageComponent implements OnInit {

  insertImageSettings ;
  public tools: object = {
    items: ['Undo', 'Redo', '|',
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };
  //public dialogHeader = 'Dialog';
  //public dialogWidth = '300px';
  //public contentData = 'dialog content';
  //public dialogdragging: Boolean = true;


  validatedForm = false;
  errorForm = false;

  pageData = {
    _id: null,
    name: "",
    presentation: "Il est possible que ces signes correspondent à",
    diagnostic: "",
    skill: "",
    folder: "",
    knowMorePageId: "",
    curesTargetPageId: "",
    explanation: ""
  }

  pages = [];
  articles = [];
  skills = [];

  /*config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '30rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        }
      ]
    };*/


  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private location: Location,
    private _pagesServices: PagesService,
    private _articlesServices: ArticlesService,
    private _practiciensService: PracticiensService,
  ) {
    this.insertImageSettings = {
      saveFormat: "Base64",
      saveUrl: ""
    }
    let params: any = this._activatedRoute.snapshot.params;

    this._pagesServices.getPage(params.id)
      .subscribe(
        res => {
          this.pageData = res;
        },
        err => {
          console.log(err);
          this._router.navigate(['/admin/idiagnose']);
        }
      )

    this._pagesServices.getPages()
      .subscribe(
        pages => {
          // console.log(pages);
          this.pages = pages;
        }
      )

      this._articlesServices.getArticles().subscribe(
        articles => {
          //console.log(articles);
          this.articles = articles;
        }
      )

      this._practiciensService.getSkill().subscribe(
        skills => {
          //console.log(skills);
          this.skills = skills;
        }
      )
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }

  onUpload() {
    this.validatedForm = false;
    this.errorForm = false;
    // console.log(this.pageData);
    this._pagesServices.postPage(this.pageData._id, this.pageData)
    .subscribe(
      res => {
        this.validatedForm = true;
      },
      err => {
        this.errorForm = true;
      }
    )
  }

  deletePage() {
    if(confirm("Êtes-vous sûr.e de vouloir supprimer " + this.pageData.name + " ?")) {
      this._pagesServices.deletePage(this.pageData._id)
      .subscribe(
        res => {
          this._router.navigate( ['/admin/idiagnose']);
        },
        err => console.log(err)
      )
    }
  }

}
